<template>
	<div class="base-input-wrapper">
		<input
			:type="type"
			:placeholder="placeholder"
			class="rounded-left rounded-right full-width-base-input"
			:class="[inputPaddingY, { 'error': !isValid }]"
			:value="modelValue"
			@input="$emit('update:modelValue', $event.target.value)"
		/>
		<div class="base-icon-circle cursor-pointer" @click="$emit('button-clicked')">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			modelValue: {
				type: String,
				default: ''
			},
			placeholder: {
				type: String,
				default: 'Enter text'
			},
			type: {
				type: String,
				default: 'text' // Allows you to change the type (e.g., password, email, etc.)
			},
			inputPaddingY: {
				type: String,
				default: 'py-3'
			},
			isValid: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {};
		},
		methods: {}
	};
</script>

<style scoped>
	/* Add any specific styling if needed */

	.base-input-wrapper {
		display: flex;
		justify-content: center; /* Center horizontally */
		align-items: center; /* Center vertically */
		position: relative;
		width: 100%;
	}
	.full-width-base-input {
		width: 100%;
		background: #fff;
		box-sizing: border-box;
		border: 1px solid #ced4da; /* Light grey border */
		padding: 1.25rem;
		border-radius: 0.25rem; /* Rounded borders */
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; /* Smooth transition for focus */
	}
	.full-width-base-input:focus {
		border-color: #14c871; /* Blue border on focus */
		outline: 0;
		box-shadow: 0 0 0 0.1rem rgb(20, 200, 113, 0.1); /* Subtle blue shadow on focus */
	}

	.base-icon-circle {
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		width: 35px;
		height: 35px;
		border-radius: 50%;
		background: #14c871;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.error {
		border-color: #ff0000;
	}
</style>
