<template>
    <div>
      <custom-modal v-if="showModal">
        <template #header>
          <div class="modal-header-content">
            <h3 class="header-title">Upgrade Now</h3>
            <button class="close-button" @click="closeModal">
              <i class="pi pi-times close-icon"></i>
            </button>
          </div>
        </template>
        <template #body>
          <div class="modal-body grid grid-row md:grid-cols-2 gap-6">
            <div class="plan-selection">
              <h4>Choose a Plan:</h4>
              <div class="plan-option" v-if="planDetails.name !== 'Startup'" @click="handlePlanOption('startup')" :class="{ 'selected': selectedPlan === 'startup' }">
                <input type="radio" v-model="selectedPlan" value="startup" class="p-radio" />
                <label>Startup</label>
              </div>
              <div class="plan-option" @click="handlePlanOption('enterprise')" :class="{ 'selected': selectedPlan === 'enterprise' }">
                <input type="radio" v-model="selectedPlan" value="enterprise" class="p-radio" />
                <label>Enterprise</label>
              </div>
            </div>
            <div class="benefits">
              <h4>Plan Details:</h4>
              <ul v-if="selectedPlan === 'startup'">
                <li><i class="pi pi-check"></i> Up to 3 models</li>
                <li><i class="pi pi-check"></i> Dataset up to 20,000</li>
                <li><i class="pi pi-check"></i> One dedicated server</li>
                <li><i class="pi pi-check"></i> Unlimited support</li>
              </ul>
              <ul v-else-if="selectedPlan === 'enterprise'">
                <li><i class="pi pi-check"></i> Unlimited models</li>
                <li><i class="pi pi-check"></i> Dataset up to 500,000</li>
                <li><i class="pi pi-check"></i> 5 free peak-time servers</li>
                <li><i class="pi pi-check"></i> Unlimited datasets</li>
                <li><i class="pi pi-check"></i> Unlimited support</li>
              </ul>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="modal-footer gap-3 mb-4">
            <Button label="Cancel" @click="closeModalUpgrade" class="p-button-outlined p-button-rounded mr-2 btn-cancel font-bold"  />
            <Button label="Upgrade" @click="handleUpgrade" :disabled="loading" rounded class="bg-theme-color color-black save-btn font-bold" />
          </div>
        </template>
      </custom-modal>
      <modal-plan-price-and-payement v-if="showModal && currentModal === 'ModalPlanPriceAndPayement'" @previous="openModal('ModalTrainingSelectServerStepOne')"   @close-modal="closeModal" />
    </div>
  </template>
  
  <script>
  import CustomModal from '../commons/modal/ModalComponent.vue'
  import Button from 'primevue/button';
  import axios from "axios";
  import ModalPlanPriceAndPayement from './ModalPlanPriceAndPayement.vue'
  
  export default {
    name: 'UpgradeModal',
    props: {
      showModal: Boolean
    },
    data() {
      return {
        selectedPlan: 'startup',
        planDetails: {}
      };
    },
    components: {
      CustomModal,
      Button,
      ModalPlanPriceAndPayement
    },
    created() {
        this.getUserDetails();
    },
    methods: {
      closeModalUpgrade() {
        this.$emit('previous');
      },
      openModal(modal) {
        this.$store.commit('openModal', modal);
      },
      closeModal() {
        this.$emit('close-modal');
      },
      handlePlanOption(option) {
        this.selectedPlan = option;
      },
      handleUpgrade() {
        const plan_id = JSON.parse(localStorage.getItem('planId'));
        const formData = {
                old_plan_id: plan_id,
                new_plan: this.selectedPlan
            };
        this.$store.dispatch('saveFormData', { selectPlan: formData });
        this.openModal('ModalPlanPriceAndPayement');
      },
      async getUserDetails() {
            try {
                const userId = JSON.parse(localStorage.getItem('userId')).userId;
                const baseUrl = process.env.VUE_APP_BASE_URL; 
                const storedTokenData = localStorage.getItem('token');
                const token = storedTokenData ? JSON.parse(storedTokenData).token : '';
                const url = `${baseUrl}/user/${userId}`;


                const response = await axios.get(url, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }});

                
                if (response.status === 200) {
                    console.log(response.data);
                    this.planDetails = response.data.plan_details; 
                    if (this.planDetails.name === 'Startup') {
                      this.selectedPlan = 'enterprise'; 
                    }
                } else {
                    console.error('Failed to fetch user details');
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        }
    }
  };
  </script>
  
  <style scoped>
  .modal-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .header-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    text-align: center;
    width: 100%;
  }

  .close-button {
      border: 2px solid red;
      background-color: red;
      border-radius: 50%;
      width: 43px;
      height: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -38px;
      left: 100%;
      right: -12px;
      cursor: pointer;
      padding: 0;
  }

  @media (max-width: 400px) {
      .close-button {
          border: 2px solid red;
          background-color: red;
          border-radius: 50%;
          width: 43px;
          height: 43px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: -38px;
          left: 100%;
          right: -12px;
          cursor: pointer;
          padding: 0;
      }
  }

  .close-icon {
      color: black;
      font-size: 20px;
  }

  .p-inputtext {
      border-radius: 9999px;
      margin-bottom: 10px;
      padding: 13px; 
      margin-bottom: 10px;
  }

  .spinner {
      width: 30px;
      height: 30px;
      justify-content: start;
      margin-left: 20px;
      --p-progress-spinner-color: green;
  }

  .error {
      border: 1px solid red;
  }

  .error-message {
      color: red;
  }

  .btn-cancel {
      background-color: transparent;
      color: #14C871;
      border: 1px solid;
  }
  .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .plan-selection {
    margin-right: 2rem;
  }
  
  .plan-option {
    cursor: pointer;
    margin-bottom: 1rem;
  }
  
  .selected {
    background-color: #f0f0f0;
  }
  
  .p-radio {
    margin-right: 0.5rem;
  }
  
  .benefits ul {
    list-style-type: none;
    padding-left: 0;
    text-align: left;
  }
  
  .benefits ul li {
    margin-bottom: 0.5rem;
  }
  
  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
  </style>
  