<template>
  <div class="modal" v-if="showModalColumn">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-header-item pdf-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20" fill="none">
            <path d="M16.268 4.488L11.808 0.096C11.748 0.036 11.664 0 11.576 0H3.348C2.104 0 1.096 1.064 1.096 2.376V11.144H0V17.088H1.096V17.624C1.096 18.932 2.108 20 3.348 20H14.124C15.368 20 16.376 18.936 16.376 17.624V4.748C16.376 4.652 16.34 4.556 16.268 4.488ZM11.464 0.736L15.344 4.56H13.232C12.256 4.56 11.464 3.78 11.464 2.824V0.736ZM15.696 17.624C15.696 18.54 14.992 19.284 14.124 19.284H3.348C2.48 19.284 1.776 18.54 1.776 17.624V17.088H9.256C10.716 17.088 11.896 15.756 11.896 14.116C11.896 12.476 10.712 11.144 9.256 11.144H1.776V2.376C1.776 1.46 2.48 0.716 3.348 0.716H10.784V2.824C10.784 4.176 11.88 5.276 13.232 5.276H15.696V17.624ZM2.104 15.616V12.616H3.252C3.584 12.616 3.844 12.7 4.028 12.872C4.212 13.044 4.308 13.268 4.308 13.548C4.308 13.828 4.216 14.056 4.028 14.224C3.84 14.392 3.58 14.48 3.252 14.48H2.604V15.616H2.104ZM4.916 15.616V12.616H5.908C6.288 12.616 6.596 12.736 6.836 12.972C7.076 13.208 7.192 13.516 7.192 13.888V14.344C7.192 14.72 7.072 15.024 6.836 15.26C6.596 15.496 6.288 15.616 5.908 15.616H4.916ZM9.58 13.936V14.336H8.372V15.616H7.868V12.616H9.788V13.016H8.368V13.932H9.58V13.936Z" fill="#14C871" />
            <path d="M3.672 13.928C3.764 13.828 3.812 13.704 3.812 13.552C3.812 13.4 3.764 13.272 3.672 13.172C3.58 13.072 3.44 13.02 3.252 13.02H2.604V14.08H3.252C3.44 14.076 3.576 14.028 3.672 13.928Z" fill="#14C871" />
            <path d="M5.90802 13.02H5.41602V15.22H5.90802C6.14802 15.22 6.34002 15.14 6.48002 14.976C6.62002 14.812 6.69202 14.604 6.69202 14.348V13.888C6.69202 13.636 6.62002 13.428 6.48002 13.264C6.34002 13.1 6.14802 13.02 5.90802 13.02Z" fill="#14C871" />
          </svg>
        </div>

        <div class="modal-header-item modaltitle">{{ modalTitle }}</div>
        <div class="close modal-header-item" @click="CloseModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
            <path d="M1.70703 1L16.707 16" stroke="#E90000" stroke-width="1.5" stroke-linecap="round" />
            <path d="M16 1L1 16" stroke="#E90000" stroke-width="1.5" stroke-linecap="round" />
          </svg>
        </div>
      </div>
      <div class="modal-body">
        <div class="input" v-if="!formatted">
          <div class="heading-indication ml-4 mr-4 mb-4">
            Click the columns you want to use as input
          </div>
          <div class="columns-choice ml-4 mr-4 mb-4">
            <div class="column" v-for="(column, index) in columns" :key="index" @click="toggleSelectColumn(column)" :class="{ 'selected-column': selectedColumns.includes(column) }">
              {{ column }}
            </div>
          </div>
        </div>
        <div v-if="selectedColumns.length && !formatted" class="output">
          <div class="heading-indication ml-4 mr-4 mb-4">
            Which one of these columns should be used as output?
          </div>
          <div class="columns-choice ml-4 mr-4 mb-4">
            <div class="column" v-for="(column, outputIndex) in columns" :key="outputIndex" @click="selectColumnOutput(column)" :class="{ 'selected-column': column === selectedOutputColumn }">
              {{ column }}
            </div>
          </div>
        </div>

        <div v-if="selectedOutputColumn && !formatted" class="button-format">
          <button class="format-button" @click="format()">
            <ProgressSpinner v-if="loading" class="spinner" />
            <span v-if="!loading">Format my data</span>
          </button>
        </div>

        <div v-if="formatted" class="inputs">
          <div class="inputs-container">
            <input type="text" placeholder="System: Determine the best outcome" v-model="input1" />
           

            <div class="button-save">
              <Button label="Save" @click="saveAndCloseModal" :icon="loading ? '' : 'pi pi-arrow-right'" :disabled="loading" rounded class="bg-theme-color color-black save-btn" />
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer"></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';

export default {
  props: {
    columns: Array,
  },
  data() {
    return {
      modalTitle: "Choose the columns",
      selectedColumns: [],
      selectedOutputColumn: '',
      loading: false,
      input1: "",
      input2: "",
      input3: "",
      formatted: false,
    };
  },
  computed: {
    ...mapState(['file_uri']),
    selectedColumnValues() {
      return this.selectedColumns;
    },
    selectedOutputColumnValue() {
      return this.selectedOutputColumn;
    },
    showModalColumn() {
      return this.$store.state.showModalColumn;
    },
    rawFileInfo() {
      return this.$store.state.rawFileInfo;
    }
  },
  components: {
    Button,
    ProgressSpinner
  },
  methods: {
    ...mapActions(['openModal', 'closeModal', 'closeModalColumn']),
    ...mapActions(['setFileInfo', 'resetFileInfo']),
    toggleSelectColumn(column) {
      const index = this.selectedColumns.indexOf(column);
      if (index === -1) {
        this.selectedColumns.push(column);
        console.log('selected column',this.selectedColumns)
      } else {
        this.selectedColumns.splice(index, 1);
      }
    },
    selectColumnOutput(column) {
      this.selectedOutputColumn = column;
    },
    async saveAndCloseModal() {
      const self = this;
      this.loading = true;
      console.log('file id',this.$store.state.file_id)
      const heading1String = this.selectedColumnValues.join(',');

      const queryParams = new URLSearchParams({
        file_id: this.$store.state.file_id,
        // file_id: "valeur du rawFileInfo",
        heading1: heading1String,
        heading2: this.selectedOutputColumnValue,
       
        system: this.input1,
        user_id: localStorage.getItem('userId'),
        model_type:localStorage.getItem('selectedModelId'),
        path: this.rawFileInfo.path,
        file_uri: this.$store.state.file_uri,
      });
      // const baseUrl = process.env.VUE_APP_BASE_URL;
      // Make an Axios POST request to send the data
      const baseUrl = process.env.VUE_APP_BASE_URL;
      axios.post(`${baseUrl}/manufacturing/?${queryParams}`)

        .then(function (response) {
          console.log(response.data);
          if (response.data) {
            console.log('valeur du reponse', response.data);
          self.setFileInfo(response.data);
          self.closeModalColumn();
          localStorage.setItem('s3Key', JSON.stringify(response.data.s3_key));
          localStorage.setItem('file_id', JSON.stringify(response.data.file_id));

          document.body.classList.remove('modal-open');
          }
        })
        .catch(function (error) {
          console.log(error);
          self.loading = false;
        });
    },
    CloseModal() {
      this.closeModalColumn();
    },
    format() {
      this.formatted = true;
    }
  }
};
</script>

<style scoped>
.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  width: 700px;
  max-width: 100%;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header-item {
  flex: 1;
}

.pdf-icon {
  width: 30px;
  height: 30px;
}

.modaltitle {
  text-align: center;
  font-weight: bold;
}

.close {
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}

.modal-body {
  overflow-y: auto;
  padding-right: 10px;
}

.input {
  display: flex;
  flex-direction: column;
}

.heading-indication {
  font-weight: bold;
  font-size: 18px;
}

.columns-choice {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.column {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.selected-column {
  background-color: #e0e0e0;
}

.button-format {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.format-button {
  background-color: #14C871;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.inputs {
  display: flex;
  flex-direction: column;
}

.inputs-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.button-save {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.save-btn {
  background-color: #14C871;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
</style>
