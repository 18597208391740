<template>
  <div class="main-content-container">
    <div class="title">TRAIN WITH DATA</div>
    <div class="upload-view">
      <div class="upload-item model-name ml-2">Model Geo npt</div>
      <div class="upload-item ml-2"></div>
      <div class="upload-item ml-2"></div>
      <div class="upload-item ml-2"></div>
      <div class="upload-item ml-2 merged-item-up">
        <div class="interface-upload ml-2">
          <div
            class="iu1"
            @click="toggleClicked('iu1')"
            :class="{ clicked: clicked === 'iu1' }"
          >
            Upload File
          </div>
          <div
            class="iu2"
            @click="toggleClicked('iu2')"
            :class="{ clicked: clicked === 'iu2', incomplete: clicked === 'iu2' }"
            :style="{ whiteSpace: 'nowrap' }"
          >
            Enter manually
          </div>
          <div
            class="iu3"
            @click="toggleClicked('iu3')"
            :class="{ clicked: clicked === 'iu3', incomplete: clicked === 'iu3' }"
            :style="{ whiteSpace: 'nowrap' }"
          >
            Scan document
          </div>
        </div>
      </div>

      <div class="upload-item picker merged-icon ml-2" @click="openFilePicker">
        <input
          type="file"
          ref="fileInput"
          class="input-file"
          @change="handleFileUpload"
          accept=".csv,.xlsx,.xls"
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22.73"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M14.2 17.1333H18.6C21.0303 17.1333 23 15.1636 23 12.7333C23 10.3031 21.0303 8.33333 18.6 8.33333H17.1333V6.13333C17.1333 3.29827 14.8351 1 12 1C9.16493 1 6.86667 3.29827 6.86667 6.13333V6.86667H6.13333C3.29827 6.86667 1 9.16493 1 12C1 14.8351 3.29827 17.1333 6.13333 17.1333H9.8"
            stroke="black"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linejoin="round"
          />
          <path
            d="M12 23.7334V9.80005"
            stroke="black"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linejoin="round"
          />
          <path
            d="M7.6 14.2L12 9.80005L16.4 14.2"
            stroke="black"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div class="upload-item merged-picker" @click="openFilePicker">
        <span class="add-files">Add files or drop files here</span>
      </div>

      <div class="upload-item mb-5 grid grid-cols gap-6">
        <div class="grid-col-span-3 custom-width ml-2 scroll-container">
          <div
            class="grid grid-cols-1 upload-item-expanded-file files-list gap-2"
            v-for="file in files"
            :key="file.id"
          >
            <div class="filename">{{ truncateText(file.name) }}</div>
            <div class="filesizw">{{ truncateText(file.path) }}</div>
            <div class="filesizw">{{ formatFileSize(file.size) }}</div>
            <div class="status">
              <div class="status-text">{{ file.status }}</div>
              <div class="delete" @click="deleteFile(file.id)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                >
                  <path
                    d="M1 1.11084L12.0313 12.1421"
                    stroke="#3E4450"
                    stroke-linecap="round"
                  />
                  <path
                    d="M1 12.0312L12.0313 0.999984"
                    stroke="#3E4450"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div
          class="grid-col-span-1 d-flex flex-column align-items-end mr-4 ml-4 mb-2"
          v-if="filepolished"
        >
          <div class="filename font-bold mb-2">Information </div>
           <!-- <div class="filename"> System :{{ fileinfo.data.file_id.system }}</div>  -->
          <div class="filename">
           <!-- Directive :{{ fileinfo.data.file_id.user_directive }}  -->
          </div>
<!--          <div class="filename"> Model :{{ fileinfo.data.file_id.model_type }}</div>
 -->       </div>
      </div>
    </div>
    <div class="training-div">
      <button class="training" @click="train">
        <span v-if="!trainingStarted" class="font-bold">Start training with data</span>
        <span v-else class="font-bold">
          Training started
          <i class="fas fa-spinner fa-spin" style="margin-left: 5px;"></i>
        </span>
      </button>
    </div>
    <!-- Modal and other components -->
    <Modal @submit="submitModal"   :columns="columns" :modalTitle="modalTitle" />
    <modal-training-data @close-modal="closeModal" />
  </div>
</template>

<script>
import axios from "axios";
import { retrieveToken } from "../services/tokenService";
import Modal from "../components/upload-modal.vue";
import { mapActions } from "vuex";
import ModalTrainingData from "../components/training/ModalTrainingData.vue";

export default {
  name: "BaseContent",

  data() {
    return {
      fileUploaded: false,
      showModalTraining: false,
      clicked: "iu1", // Track which item is clicked
      files: [],
      file: null,
      fileUploading: false,
      uploadProgress: 0,
      modalTitle: "Your Modal Title", // Set the title of the modal
      columns: [],
    };
  },

  computed: {
    rawFileInfo() {
      return this.$store.state.rawFileInfo;
    },

    apiKey() {
      return this.$store.state.api_key;
    },
    showModal() {
      return this.$store.state.showModalColumns;
    },
    fileinfo() {
      return this.$store.state.fileInfo;
    },
    filepolished() {
      return this.$store.state.filepolished;
    },
    trainingStarted() {
      return this.$store.state.trainingStarted;
    },
    showModalColumn(){
      return this.$store.state.showModalColumn;


    },
  },

  methods: {
    ...mapActions(["setTrainingStarted"]),
      goToStep(step) {
      this.$store.commit("setStep", step);
    },
    opelModal() {
      this.showModalTraining = true;
    },
    closeModal() {
      this.showModalTraining = false;
    },
    openFilePicker() {
      this.$refs.fileInput.click();
    },
    ...mapActions(["setFilePolished"]),
    ...mapActions(["openModalColumn"]),
    ...mapActions(["setRawFileInfo"]),
    ...mapActions(["setRawFileInfo"]),


    truncateText(text) {
      const maxLength = 10;
      if (text.length <= maxLength) {
        return text;
      }
      return text.substring(0, maxLength) + "...";
    },
    deleteFile(fileId) {
      this.files = this.files.filter((file) => file.id !== fileId);
    },

    handleFileUpload(event) {
      const files = event.target.files;
      if (files.length > 0) {
        this.uploadFile(files);
      }
    },

    formatFileSize(sizeInBytes) {
      const fileSizeInKB = sizeInBytes / 1024;
      return fileSizeInKB.toFixed(2) + " KB";
    },

    async train() {
      try {
        const baseUrl = process.env.VUE_APP_BASE_URL;
        let apiKeyString = localStorage.getItem('apiKey');

// Parse the JSON string back into an object or value
          let apiKey = JSON.parse(apiKeyString);

          // Now you can use the apiKey as needed
        console.log('api key',apiKey);

        let s3KeyString = localStorage.getItem('s3Key');

// Parse the JSON string back into an object or value
        let s3Key = JSON.parse(s3KeyString);

// Now you can use the apiKey as needed
        console.log(s3Key);
        let fileUrl = s3Key // Assuming this is the file URL

        console.log('API key', apiKey);
        console.log('File URL', s3Key);

        // this.setTrainingStarted(true);

        // // Make sure both API key and file URL are available
        if (!apiKey || !fileUrl) {
          console.error('API key or file URL is missing');
          return;
        }

        // Make your API call using Mock Axios
        const response = await axios.post(`${baseUrl}/fine-tune-language-model`, {
        // const response = await axios.post(`/api/fine-tune-language-model`, {
          fileUrl: fileUrl,
          apiKey: apiKey
          // Add any other data you need to send
        });

        // Handle the response
        console.log('Response:', response.data);
        this.setTrainingStarted(false);
        this.$store.commit('updateCurrentComponent', 'testingphase');
        this.goToStep(3);


      } catch (error) {
        // Handle errors
        console.error('Error:', error);
        this.setTrainingStarted(false);
      }
    },

    toggleClicked(item) {
      // Toggle clicked state based on the clicked item
      this.clicked = item;
    },
    mockUploadFile() {
      console.log("Mock upload in development mode");
      setTimeout(() => {
        this.fileUploading = false;
        this.uploadProgress = 100;
        this.fileUploaded = true;
        this.columns = ['Outcome', 'Possible Cause', 'Final Outcome'];
        this.openModal();
      }, 1000); // Simulate async delay
    },

    async uploadFile(files) {
  console.log(files);
  if (files.length === 0) return;

  const file = files[0];
  this.files = [{
    id: file.name, // or another unique property
    name: file.name,
    size: file.size,
    type: file.type,
    path: file.webkitRelativePath || file.name,
    status: 'Ready for upload'
  }];
  this.file = file;

  const token = retrieveToken();

  if (!token || !this.file) {
    console.log('eto');
    this.fileUploading = false;
    return;
  }

  const userData = localStorage.getItem('userId');
  const userId = userData ? JSON.parse(userData).userId : null;

  const formData = new FormData();
  formData.append("user_id", userId);
  formData.append("file", this.file);
  formData.append("path", this.file.webkitRelativePath || this.file.name);

  try {
    const baseUrl = process.env.VUE_APP_BASE_URL;

    console.log('eto');
    const response = await axios.post(`${baseUrl}/upload-csv/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      timeout: 60000 * 30, // Adjust the timeout as needed
      onUploadProgress: () => {
        // Calculate the upload progress percentage
        this.uploadProgress = 75;
        // Update the UI with the upload progress if needed
      },
    });
    this.fileUploading = false;
    this.uploadProgress = 0;
    this.openModalColumn()
    console.log(' this open modal',this.$store.state.showModalColumn)
    console.log('voici le data ', response.data);
    this.fileUploaded = true;
    this.columns = response.data.column_headings;
    this.setRawFileInfo(response.data);

  } catch (error) {
    this.fileUploading = false;
    this.uploadProgress = 0;
  }
}
,
  },

  components: {
    Modal,
    ModalTrainingData
  }
};

  
</script>

<style scoped>
.upload-view {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  border-radius: 20px;
  background: #f4f4f4;
  padding: 20px;
  overflow-y: scroll;
}

.upload-item {
  width: 90%;
  /* Each item takes up 25% of the container width */
  height: fit-content;
  margin-bottom: 1rem;
  /* Add margin between each line */
}

.title {
  margin-bottom: 2rem;
  margin-top: 0.5rem;
  text-align: left;
  color: #3e4450;
  text-indent: 0;
  /* Adjust the indentation as needed */
  text-align: justify;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.model-name {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.merged-item-up {
  width: calc(100% - 30px);
  /* Full width minus the sum of right margins and spacing */
  border: 1px solid rgba(20, 200, 113, 0.15);
  background: #fff;
  border-radius: 27.5px;
  display: flex;
  /* Use flexbox to center the content */
  justify-content: center;
  /* Center-align the content horizontally */
  align-items: center;
  align-items: stretch;
  /* Make the height of the item wrap its content */
}
@media (max-width: 768px) {
  .merged-item-up {
    padding:  10px 40px 10px 40px;
    justify-content: center;
    width: calc(100%);
  }
}
.interface-upload .item {
  flex: 1;
  text-align: center;
  cursor: pointer;
  padding: 10px;
  margin: 5px;
  transition: background-color 0.3s ease;
}
@media (max-width: 768px) {
  .interface-upload {
    flex-direction: column;
    padding:  20px 50px 20px 50px;
  }

  .interface-upload .item {
    margin-bottom: 10px;
  }
}
.interface-upload {
  display: flex;
  justify-content: space-between;
  /* Align children elements with space in between */
  margin-right: 1rem;
  /* Spacing between items */
  border-radius: 27.5px;
  justify-content: center;
  /* Center-align the content horizontally */

  align-items: center;
  /* Make the height of the item wrap its content */
}

.interface-upload > div {
  flex: 1;
  /* Each child takes up equal space */
  text-align: center;
  /* Center-align the text */
  padding: 10px 40px 10px 40px;
  /* Add padding for spacing */
  border-radius: 20px;
  /* Example border radius */
  /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); Example box shadow */
}

.interface-upload > div:not(:last-child) {
  margin-right: 2rem;
  /* Spacing between items */
}
/* Apply styles to the clicked item */
.clicked {
  border-radius: 20px;
  background: #14c871;
  color: white;
}

.incomplete {
  background: grey;
  border-radius: 20px;
  color: white; /* Optionally, add text color to improve readability */
}
.merged-icon,
.merged-picker {
  display: flex; /* Use flexbox */
  flex-direction: column; /* Stack icon and text vertically */
  justify-content: center; /* Center items vertically */
  align-items: center; /* Center items horizontally */
  flex-basis: calc(50% - 40px); /* Adjusted to accommodate padding */
  background: #fff;
  margin-bottom: 50px; /* Add margin to create space between items */
  margin-top: 50px; /* Add margin to create space between items */
  height: 20vh; /* Adjust the height as needed, here set to 20% of the viewport height */
}

.merged-picker {
  display: flex; /* Use flexbox */
  align-items: center; /* Center items vertically */
  flex-basis: calc(50% - 40px); /* Adjusted to accommodate padding */
  background: #fff;
  height: 20vh; /* Adjust the height as needed, here set to 20% of the viewport height */
  flex-grow: 1; /* Let the text grow to fill the available space */
}

.add-files {
  color: #14c871; /* Keep the color */
}

.merged-icon {
  display: flex; /* Use flexbox */
  flex-direction: row-reverse; /* Reverse the order to make the icon appear on the right */
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  flex-basis: calc(50% - 50px); /* Adjusted to accommodate padding */
  background: #fff;
  margin-bottom: 10px; /* Add margin to create space between items */
  height: 20vh; /* Adjust the height as needed, here set to 20% of the viewport height */
}

.merged-icon > svg {
  margin-left: auto; /* Move the SVG icon to the right */
  margin-right: 20px; /* Add some spacing between the icon and text */
}
/* Create a new class for items 13 to 16 */
.files-list {
  flex-grow: 1; /* Let this item occupy the remaining space */
  width: 100%;
  display: flex;
  justify-content: center; /* Align items horizontally to the center */
  margin-bottom: 4px;
  margin-left: 10px;
}

.files-list > * {
  flex: 1; /* Make each child element occupy equal space */
}
.status {
  display: flex; /* Use flexbox */
  gap: 30px; /* Adjust the spacing value as needed */
  width: 50%;
  flex-direction: row;
  justify-content: center; /* Center items horizontally */
}
.delete {
  width: 50%;
  display: flex;
  justify-content: center;
}
.delete > svg {
  text-align: center; /* Align the SVG vertically in the center */
}
.status-text {
  width: 50%;
}
.start-train {
  width: 40%;
  border-radius: 25px;
  margin-left: 10px;
  margin-top: 20px;
}
.training {
  border-radius: 25px;
  background: #14c871;
  border-color: #14c871;
  padding: 10px 15px 10px 15px;
}
@media (max-width: 768px) {
  .training {
    white-space: nowrap;
  }
}
@media (max-width: 400px) {
  .training {
    white-space: nowrap;
    justify-content:center;
  }
}
.main-content-container {
  margin-left: 2rem;
}
.input-file {
  /* Add styles to make the file input visible */
  position: absolute;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
}
.upload-item-expanded-file {
  background-color: rgba(20, 200, 113, 0.15);
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  color: #000000; /* Noir */
}
.upload-item-expanded-file .status-text {
  color: #000000; /* Noir */
}

.upload-item-expanded-file .filename,
.upload-item-expanded-file .filesize {
  color: #000000; /* Noir */
}

.delete svg path {
  stroke: #000000; /* Noir */
}
.training-div {
  margin-left: 30px;
  margin-bottom: 20px;
  margin-top: 10px;
  top: -60px; /* Example padding */
  position: relative; /* Adjust as needed */
  /* Add any other styles specific to .training-div */
}
.custom-width {
  margin-top: 10px;
  width: 75%; /* Spécifiez la largeur souhaitée */
  flex-wrap: wrap;
}

/* Pour les écrans de petite taille et plus petits */
@media (max-width: 576px) {
  .custom-width {
    width: 100%; /* Occupe toute la largeur */
    margin-left: 0; /* Supprimez la marge si nécessaire */
  }
}

/* Pour les écrans de taille moyenne */
@media (min-width: 576px) and (max-width: 768px) {
  .custom-width {
    width: 70%;
    margin-left: 10px; /* Ajoutez la marge sur les côtés */
  }
}

/* Pour les écrans de grande taille */
@media (min-width: 768px) {
  .custom-width {
    width: 75%; /* Restez avec la largeur de 75% */
    margin-left: 10px;
  }
}
.scroll-container {
  max-height: 300px;
  overflow-y: scroll; /* Enable vertical scroll */
}
</style>