<template>
  <div class="modal">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="header-title">Verify Ownership for {{ domain }}</h4>
        <button class="close-button" @click="handleCloseModal">
          <i class="pi pi-times close-icon" @click="closeModalIcon"></i>
        </button>
      </div>
      <div class="modal-body">
        <p>Add these records to your host</p>

        <div v-if="loading" class="loading-text">Loading...</div>
        <div v-else>
          <div class="record">
            <div class="records-heading">
              <div class="record-name">Name:</div>
              <div class="record-value">Value:</div>
            </div>
            <div class="records-content">
              <div class="record-name-content">{{ name }}</div>
              <div class="record-value-content">{{ value }}</div>
             
            </div>
          </div>
          <!-- Specific A Record Display -->
          <div class="record">
            <div class="records-heading">
              <div class="record-name">A Record:</div>
              <div class="record-value">Value:</div>
            </div>
            <div class="records-content">
              <div class="record-name-content">A</div>
              <div class="record-value-content">3.142.85.0</div>
            </div>
            <div v-if="verificationStatus === 'pending'">
                <button @click="verifyDomain(domain)">Verify domain</button>
             
              </div>
              <div v-else-if="verificationStatus === 'success'">
                <i class="pi pi-check green-check-icon"></i>
              </div>
              <div v-else>
                <div class="error-div">
                  <i class="pi pi-times red-x-icon"></i>
                  <button @click="verifyDomain(domain)">Retry Verification</button>
                </div>
              </div>
          </div>
        </div>
      </div>
      <button :class="saveButtonClass" :disabled="verificationStatus !== 'success'" @click="installDDS">Save</button>
     
    </div>
  </div>
</template>




<script>
import axios from 'axios';
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      loading: true,
      name: '',
      record_type: '',
      value: '',
      domain: this.$store.state.domainName,
      verificationStatus: 'pending', // Initial verification status
      enableSave: false
    };
  },
  computed: {
    ...mapState(['domainName', 'showModal']),
    saveButtonClass() {
      return this.verificationStatus === 'success' ? 'btn-enabled' : 'btn-disabled';
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions(['closeModal', 'closeModalVerifyOwnership']),
    installDDS() {
      if (this.verificationStatus === 'success') {
        this.installDomain()
          .then(() => {
            this.handleCloseModal();
          })
          .catch(error => {
            console.error('Failed to validate domain:', error.message);
          });
      }
    },
    installDomain() {
  const baseUrl = process.env.VUE_APP_BASE_URL;
  const domain = encodeURIComponent(this.domain);
  const apiKey = encodeURIComponent(JSON.parse(localStorage.getItem('apiKey')));

  const installUrl = `${baseUrl}/installdomain/?domain=${domain}&api_key=${apiKey}`;

  // Make the GET request with the query parameters
  return axios.get(installUrl);
},
    fetchData() {
      const userId = localStorage.getItem('userId');
      const modelId = localStorage.getItem('selectedModelId');
      localStorage.removeItem('selectedModelId');

      const url = `${process.env.VUE_APP_BASE_URL}/token-generation/${this.domain}`;

      if (!this.domain) {
        console.error('Domain name is not set in the store');
        this.loading = false;
        return;
      }

      const params = {
        user_id: userId,
        model_id: modelId
      };

      axios.post(url, {}, { params })
        .then(response => {
          this.name = response.data.name;
          this.record_type = response.data.record_type;
          this.value = response.data.value;
          this.loading = false;
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          this.loading = false;
        });
    },
    verifyDomain(domain) {
      const verifyUrl = `${process.env.VUE_APP_BASE_URL}/verify-domain/check`;
      axios.post(verifyUrl, { domain: domain })
        .then(response => {

          if (response.status === 200) {
            console.log('Verification successful:', response.data);
            this.verificationStatus = 'success'; // Update verification status
          } else {
            console.error('Unexpected response:', response);
            this.verificationStatus = 'error'; // Update verification status to handle error case
          }

        })
        .catch(error => {
          console.error('Verification failed:', error);
          this.verificationStatus = 'error'; // Update verification status to handle error case
        });
        
    },
    handleCloseModal() {
      this.closeModal();
      console.log('Closing modal');
    },
    closeModalIcon() {
      this.closeModalVerifyOwnership();
    }
  },
};
</script>


<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure modal appears above other content */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%; /* Adjust width as needed */
  max-width: 600px; /* Example max-width for responsiveness */
  max-height: 80%; /* Example max-height for responsiveness */
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Example box shadow */
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.header-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2; /* Adjust line height as needed */
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

.close-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.close-icon {
  color: #333;
  font-size: 20px;
}

.modal-body {
  margin-top: 20px; /* Adjust top margin as needed */
  margin-bottom: 20px; /* Adjust bottom margin as needed */
}

.loading-text {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

.record {
  margin-bottom: 15px; /* Adjust bottom margin as needed */
  padding-bottom: 10px; /* Adjust bottom padding as needed */
  border-bottom: 1px solid #ccc;
}

.record-name {
  font-weight: bold;
  margin-bottom: 5px; /* Adjust bottom margin as needed */
}

.record-value {
  color: #555; /* Example text color for value */
}

.btn-close {
  margin-top: 15px; /* Adjust top margin as needed */
  padding: 10px 20px;
  background-color: #007bff; /* Example background color */
  color: white; /* Example text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-close:hover {
  background-color: #0056b3; /* Example hover background color */
}

.instructions {
  margin-bottom: 20px; /* Adjust bottom margin as needed */
  padding: 10px;
  background-color: #f0f0f0; /* Example background color */
  border: 1px solid #ccc;
  border-radius: 5px;
}
.records-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px; /* Adjust margin as needed */
}
.btn-enabled {
  background-color: #007bff; /* Example enabled background color */
}

.btn-disabled {
  background-color: #ccc; /* Example disabled background color */
}

.btn-close:hover {
  background-color: #0056b3; /* Example hover background color */
}

.records-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px; /* Adjust margin as needed */
}
.instructions p {
  margin: 5px 0; /* Adjust paragraph margin as needed */
}
.red-x-icon {
  color: red;
  font-size: 24px; /* Adjust size as needed */
  margin-left: 5px; /* Adjust spacing as needed */
}
.error-div {
  display: flex;
  align-items: center; /* Vertical center alignment */
}
@media (max-width: 768px) {
  .modal-content {
    width: 90%; /* Adjust width for smaller screens */
  }
}

@media (max-width: 480px) {
  .modal-content {
    width: 95%; /* Adjust width for mobile screens */
  }
  .green-check-icon {
    color: green;
    font-size: 24px; /* Adjust size as needed */
    margin-left: 5px; /* Adjust spacing as needed */
  }
}
</style>
