<template>
    <div class="tutor-container">
      <div class="icon-container">
        <i
          v-if="!isListening && isEligible"
          class="pi pi-microphone big-icon"
          @click="startListening"
        ></i>
        <i
          v-else-if="isListening"
          class="pi pi-microphone-slash big-icon"
          @click="stopListening"
        ></i>
        <i
          v-else
          class="pi pi-microphone big-icon"
          :class="{ 'disabled-icon': !isEligible }"
          title="You are not eligible to start listening."
        ></i>
      </div>
  
      <div class="options-container">
        <!-- Mode selection -->
        <label for="mode-select">Choose Mode:</label>
        <select id="mode-select" v-model="selectedMode">
          <option value="rolePlay">Role-Play Agent</option>
          <option value="pitchAnalyzer">Pitch Analyzer</option>
        </select>
  
        <!-- Industry selection -->
        <label v-if="selectedMode === 'rolePlay'" for="industry-select">Choose Industry:</label>
        <select v-if="selectedMode === 'rolePlay'" id="industry-select" v-model="selectedIndustry">
          <option v-for="industry in industries" :key="industry" :value="industry">
            {{ industry }}
          </option>
        </select>
  
        <!-- Attitude selection -->
        <label v-if="selectedMode === 'rolePlay'" for="attitude-select">Choose Agent's Attitude:</label>
        <select v-if="selectedMode === 'rolePlay'" id="attitude-select" v-model="selectedAttitude">
          <option value="polite">Polite</option>
          <option value="assertive">Assertive</option>
          <option value="rude">Rude</option>
          <option value="empathetic">Empathetic</option>
          <option value="indifferent">Indifferent</option>
          <option value="enthusiastic">Enthusiastic</option>
        </select>
      </div>
  
      <div class="button-container">
        <button
          class="stop-button"
          :disabled="!isListening"
          @click="sendStopToServer"
        >
          Stop
        </button>
      </div>
  
      <div class="text-container">
        <p class="description-text">{{ statusMessage }}</p>
  
        <!-- Conversation messages -->
        <div class="conversation-container">
          <p
            v-for="(msg, index) in messages"
            :key="index"
            class="conversation-message"
          >
            <strong>{{ msg.speaker }}:</strong> {{ msg.content }}
          </p>
        </div>
  
        <!-- Verification and eligibility status -->
        <p v-if="isVerified" class="verification-status">You are verified!</p>
        <p v-else class="verification-status">
        </p>
      </div>
  
      <!-- Modal for non-verified users -->
      <div v-if="!isVerified && !isEligible" class="modal-overlay" @click="closeModal">
        <div class="modal-content" @click.stop>
          <p class="modal-message">Out of credits. Please buy credits to continue.</p>
          <button class="modal-button" @click="redirectToBilling">Go to Billing</button>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import Vapi from '@vapi-ai/web';
  import axios from 'axios';
  
  // Initialize router and Vapi
  const router = useRouter();
  const vapi = new Vapi('e0b07eb5-6fb0-4da1-ac30-3a8436d59a7d');
  const messages = ref([]);
  const isListening = ref(false);
  const statusMessage = ref(
    'Click the microphone to start practicing your sales pitch or receive coaching feedback!'
  );
  
  // User info and state
  const userId = localStorage.getItem('userId');
  const isVerified = ref(localStorage.getItem('isVerified') === 'true');
  const isEligible = ref(true);
  
  const baseUrl = process.env.VUE_APP_BASE_URL;
  
  // Selection state
  const selectedMode = ref('rolePlay');
  const selectedIndustry = ref('');
  const selectedAttitude = ref('polite'); // New attitude state
  const industries = ref([
    'Technology',
    'Healthcare',
    'Finance',
    'Real Estate',
    'Retail',
    'Education',
    'Hospitality',
    'Manufacturing',
    'Automotive',
    'Logistics',
  ]);
  
  // Check eligibility
  const checkEligibility = async () => {
    try {
      const response = await axios.get(`${baseUrl}/check-eligibility`, { params: { userId } });
      isEligible.value = response.data.status === 'eligible';
    } catch (error) {
      //console.error('Error fetching eligibility status:', error);
      isEligible.value = false;
    }
  };
  
  // Modal-related actions
 // const closeModal = () => console.log('Modal closed');
  const redirectToBilling = () => router.push('/billing');
  const sendStopToServer = async () => {
  stopListening();

  const timestamp = new Date().toISOString();
  isListening.value = false;
  statusMessage.value = 'Stopped tracking. Call ended.';

  try {
    await axios.post(`${baseUrl}/update-conversation-status/`, {
      user_id: userId,
      status: 'end',
      timestamp: timestamp,
    });
    //console.log('Stop status sent successfully.');
  } catch (error) {
   // console.error('Error sending stop status:', error);
  }
};
  // Listening and assistant logic
  const startListening = () => {
    isListening.value = true;
    statusMessage.value = 'Listening... Speak now!';
    vapi.start(getAssistantOptions());
  };
  
  const stopListening = () => {
    isListening.value = false;
    statusMessage.value = 'Stopped listening.';
    vapi.stop();
  };
  
  // Generate assistant options dynamically based on selections
  const getAssistantOptions = () => {
  const roleMessage =
    selectedMode.value === 'rolePlay'
      ? `You are acting as a customer in a role-play exercise within the ${selectedIndustry.value} industry. Always respond as a customer would and never take on the role of the sales representative.`
      : 'You are analyzing sales pitches and providing detailed feedback.';

  const attitudeMessage =
    selectedMode.value === 'rolePlay'
      ? `Your attitude as the customer is: ${getAttitudeMessage(selectedAttitude.value)}.`
      : '';

  return {
    name: 'Sales Coach',
    firstMessage: `Hello! I'm your sales coach. Let's ${
      selectedMode.value === 'rolePlay' ? 'role-play' : 'analyze pitches'
    }!`,
    transcriber: { provider: 'deepgram', model: 'nova-2', language: 'en-US' },
    voice: { provider: 'playht', voiceId: 'jennifer' },
    model: {
      provider: 'openai',
      model: 'gpt-4',
      messages: [
        {
          role: 'system',
          content: `You are a versatile sales coach. ${roleMessage} ${attitudeMessage}`,
        },
      ],
    },
  };
};


  
  // Get attitude-specific message
  const getAttitudeMessage = (attitude) => {
    switch (attitude) {
      case 'polite':
        return 'You are being polite and respectful.';
      case 'assertive':
        return 'You are being confident and assertive.';
      case 'rude':
        return 'You are being rude and dismissive.';
      case 'empathetic':
        return 'You are showing empathy and understanding.';
      case 'indifferent':
        return 'You are acting indifferent and uninterested.';
      case 'enthusiastic':
        return 'You are being very enthusiastic and energetic.';
      default:
        return '';
    }
  };
  
  onMounted(() => {
    checkEligibility();
    vapi.on('message', (message) => {
      if (message.type === 'transcript') {
        const speaker = message.role === 'user' ? 'You' : 'Sales Coach';
        messages.value.push({ speaker, content: message.transcript });
      }
    });
  });
  </script><style scoped>
/* General Layout Styles */
/* General Layout Styles */
.tutor-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
}

.icon-container {
  margin-bottom: 20px;
}

.big-icon {
  font-size: 80px;
  color: #4ebf66;
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
}

.big-icon:hover {
  color: #25b09b;
  transform: scale(1.1);
}

.disabled-icon {
  color: #ccc;
  cursor: not-allowed;
}

.button-container {
  margin: 20px;
}

.stop-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #d9534f;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.stop-button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

.stop-button:hover:not(:disabled) {
  background-color: #c9302c;
}

.text-container {
  max-width: 600px;
  padding: 0 20px;
}

.description-text {
  font-size: 1.2rem;
  color: #3e4450;
  margin-bottom: 20px;
}

.verification-status {
  font-size: 1rem;
  color: #28a745;
  font-weight: bold;
}

/* Form Selector Styles */
.options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

label {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
}

select {
  width: 250px;
  padding: 12px 15px;
  font-size: 1rem;
  color: #333;
  background-color: #f9f9f9;
  border: 2px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s ease;
  margin-bottom: 20px;
}

select:focus {
  border-color: #4ebf66;
  box-shadow: 0 0 5px rgba(78, 191, 102, 0.5);
}

select option {
  padding: 8px;
}

/* Industry Selection */
#industry-select {
  width: 250px;
  padding: 12px 15px;
  font-size: 1rem;
  color: #333;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 5px;
  transition: all 0.3s ease;
}

#industry-select:focus {
  border-color: #4ebf66;
  box-shadow: 0 0 5px rgba(78, 191, 102, 0.5);
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 100%;
  max-width: 350px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.modal-content:hover {
  transform: scale(1.05);
}

.modal-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-button:hover {
  background-color: #0056b3;
}

/* Conversation Container Styles */
.conversation-container {
  max-width: 600px;
  margin-top: 20px;
  text-align: left;
  background: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow-y: auto;
}

.conversation-message strong {
  color: #4ebf66;
}

/* Responsive Design */
@media (max-width: 768px) {
  .tutor-container {
    padding: 10px;
  }

  .big-icon {
    font-size: 60px;
  }

  .stop-button {
    padding: 8px 16px;
  }

  .text-container {
    padding: 0 10px;
  }

  .modal-content {
    width: 90%;
    max-width: 300px;
  }

  .conversation-container {
    max-height: 200px;
  }
}

</style>
