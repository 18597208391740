<template>
  <div class="py-2 px-4">
    <div class="flex flex-row justify-content-start">
      <div class="main-theme-color text-3xl font-bold">Test / Endpoint</div>
    </div>

    <!-- "Test the Model" Section -->
    <div class="main-content-container">
      <div class="main-left-column">
        <div class="card flex flex-column md:flex-row gap-3">
          <div class="input-group">
            <input
              type="text"
              placeholder="Enter input"
              :class="['input-text rounded-full w-full font-bold', { 'border-red': isInvalid }]"
              v-model="testField"
            />
            <Button
              label="Test"
              @click="testFunction"
              icon="pi pi-arrow-up-right"
              rounded
              class="bg-theme-color color-black search-button font-bold"
            />
          </div>
        </div>
        <div class="result mt-3">
          <span v-if="result" class="result-title">Result :</span>
          <br />
          <div class="py-6 juice:py-[18px] px-3 text-base md:px-4 m-auto md:px-5 lg:px-1 xl:px-5">
            <div v-if="isLoading" class="columns-container-spinner">
              <p class="container-spinner"></p>
            </div>
            <span class="result mt-6" v-else>{{ result }}</span>
          </div>
          <div class="flex align-items-center justify-end mt-2 ml-4 mb-4 font-bold border-round m-2 mt-4">
            <Button
              label="Deploy"
              @click="deploy"
              :icon="loading ? '' : 'pi pi-arrow-right'"
              :disabled="loading || isPlanIdDeactivated"
              :class="{'deactivated-button': isPlanIdDeactivated}"
              rounded
              class="bg-theme-color color-black save-btn font-bold"
            />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script setup>
import { ref } from 'vue';
import axios from 'axios';
import Button from 'primevue/button';

const testField = ref('');
const result = ref('');
const isLoading = ref(false);
const isInvalid = ref(false);

const testFunction = async () => {
  if (testField.value.trim() === '') {
    isInvalid.value = true;
    return;
  } else {
    isInvalid.value = false;
    isLoading.value = true;
  }

  const requestBody = {
    prompt: testField.value,
    model_type: localStorage.getItem('selectedModelId'),
  };

  try {
    const response = await axios.post('https://sh.islandaiforge.us/generate', requestBody);
    result.value = response.data.generated_text;
    isLoading.value = false;
  } catch (error) {
    console.error('Error:', error);
    result.value = 'Error testing model';
    isLoading.value = false;
  }
};

const deploy = () => {
  // Logic for deploying the model
  // Example: this.$router.push('/deploy-page');
};

const isPlanIdDeactivated = ref(false); // Example condition for enabling/disabling the "Deploy" button

</script>
<style scoped>
.main-content-container {
  width: 75%;
}

.main-left-column {
  border-radius: 10px;
  overflow: hidden;
  margin-right: 40px;
  color: #3e4450;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  margin-left: 2%;
}

.input-group {
  position: relative;
  width: 100%;
  margin-top: 2%;
}

.input-text {
  width: 100%;
  padding: 15px 10px 15px 10px; /* Add padding to make room for the button */
  border: 1px solid #ccc;
  border-radius: 9999px; /* Fully rounded */
  box-sizing: border-box; /* Ensure padding is included in the total width */
  font-size: 15px;
}

.search-button {
  position: absolute;
  right: 10px; /* Position the button inside the input */
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: #4ebf66;
  color: black;
  padding: 5px 25px;
  cursor: pointer;
  height: calc(100% - 12px); /* Adjust height to fit inside input */
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-button:hover {
  background-color: #a7e6b4;
}

.border-red {
  border: 2px solid red;
}

.columns-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Ensures alignment to the left */
  width: 100%;
}

.columns-container-spinner {
  display: flex;
  justify-content: center;
  align-items: center; /* Ensures alignment to the left */
  width: 100%;
}

.container-spinner {
  --d: 50px;
  width: 20px;
  margin-top: 10%;
  height: 20px;
  border-radius: 50%;
  color: #25b09b;
  box-shadow: calc(1 * var(--d)) calc(0 * var(--d)) 0 0, calc(0.707 * var(--d)) calc(0.707 * var(--d)) 0 1px,
    calc(0 * var(--d)) calc(1 * var(--d)) 0 2px, calc(-0.707 * var(--d)) calc(0.707 * var(--d)) 0 3px,
    calc(-1 * var(--d)) calc(0 * var(--d)) 0 4px, calc(-0.707 * var(--d)) calc(-0.707 * var(--d)) 0 5px,
    calc(0 * var(--d)) calc(-1 * var(--d)) 0 6px;
  animation: l27 1s infinite steps(8);
}

@keyframes l27 {
  100% {
    transform: rotate(1turn);
  }
}

/* Add styles for the deactivated button */
.deactivated-button {
  background-color: grey;
  cursor: not-allowed;
  color: white;
}
</style>
