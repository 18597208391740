<template>
  <div class="tutor-container">
    <div class="icon-container">
      <!-- Microphone icons with eligibility and listening logic -->
      <i
        v-if="!isListening && isEligible"
        class="pi pi-microphone big-icon"
        @click="startListening"
      ></i>
      <i
        v-else-if="isListening"
        class="pi pi-microphone-slash big-icon"
        @click="stopListening"
      ></i>
      <i
        v-else
        class="pi pi-microphone big-icon"
        :class="{ 'disabled-icon': !isEligible }"
        title="You are not eligible to start listening."
      ></i>
    </div>

    <div class="button-container">
      <button
        class="stop-button"
        :disabled="!isListening"
        @click="sendStopToServer"
      >
        Stop
      </button>
    </div>

    <div class="text-container">
      <p class="description-text">{{ statusMessage }}</p>

      <!-- Conversation messages -->
      <div class="conversation-container">
        <p
          v-for="(msg, index) in messages"
          :key="index"
          class="conversation-message"
        >
          <strong>{{ msg.speaker }}:</strong> {{ msg.content }}
        </p>
      </div>

      <!-- Verification and eligibility status -->
      <p v-if="isVerified" class="verification-status">You are verified!</p>
      <p v-else class="verification-status">
      </p>
    </div>

    <!-- Modal for non-verified users -->
    <div v-if="!isVerified && !isEligible" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <p class="modal-message">Out of credits. Please buy credits to continue.</p>
        <button class="modal-button" @click="redirectToBilling">Go to Billing</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import Vapi from '@vapi-ai/web';
import axios from 'axios';

// Initialize Vapi with your public key
const vapi = new Vapi('e0b07eb5-6fb0-4da1-ac30-3a8436d59a7d');
const messages = ref([]);

// States to control listening and provide feedback
const isListening = ref(false);
const statusMessage = ref(
  'Click the microphone to start practicing your English speaking skills. Speak clearly to receive feedback and improve!'
);

// Assume these are stored in localStorage or fetched via API
const userId = localStorage.getItem('userId');
const isVerified = ref(localStorage.getItem('isVerified') === 'true'); // Assuming this is how you check verification status
const isEligible = ref(true); // Default value, set based on your logic

const baseUrl = process.env.VUE_APP_BASE_URL;

// Fetch the user's eligibility status (e.g., if they're on a free trial or have remaining minutes)
const checkEligibility = async () => {
  try {
    const response = await axios.get(`${baseUrl}/check-eligibility`, { params: { userId } });
   // console.log('reponse',response.data)
    if(response.data.status ==='eligible'){
      isEligible.value = response.data.status; // Expect a boolean response like { isEligible: true/false }
      }
    else{
      isEligible.value = false; // Set to false if there's an error fetching the status

    }
  } catch {
  //  console.error('Error fetching eligibility status:', error);
    isEligible.value = false; // Set to false if there's an error fetching the status
  }
};

// Function to start listening
const startListening = () => {
  isListening.value = true;
  statusMessage.value = 'Listening... Speak now!';
  vapi.start(assistantOptions);
};

// Function to stop listening
const stopListening = () => {
  isListening.value = false;
  statusMessage.value = 'Stopped listening. Click the microphone to start again.';
  vapi.stop();
};

// Function to explicitly send "stop" event to the server
const sendStopToServer = async () => {
  stopListening();

  const timestamp = new Date().toISOString();
  isListening.value = false;
  statusMessage.value = 'Stopped tracking. Call ended.';

  try {
    await axios.post(`${baseUrl}/update-conversation-status/`, {
      user_id: userId,
      status: 'end',
      timestamp: timestamp,
    });
   // console.log('Stop status sent successfully.');
  } catch (error) {
  //  console.error('Error sending stop status:', error);
  }
};

// Listen to Vapi events
onMounted(() => {
  // Check if the user is eligible when the component is mounted
  checkEligibility();

  vapi.on('call-start', async () => {
    const timestamp = new Date().toISOString();
    statusMessage.value = 'Assistant connected. You can start speaking.';

    try {
      await axios.post(`${baseUrl}/update-conversation-status/`, {
        user_id: userId,
        status: 'start',
        timestamp: timestamp,
      });
      //console.log('Conversation start status sent successfully.');
    } catch (error) {
      //console.error('Error sending start status:', error);
    }
  });

  vapi.on('call-end', () => {
    isListening.value = false;
    statusMessage.value = 'Call ended. Click the microphone to start again.';
  });

  vapi.on('speech-start', () => {
    statusMessage.value = 'Processing your speech...';
  });

  vapi.on('speech-end', () => {
    statusMessage.value = 'Received input. Processing your feedback...';
  });

  vapi.on('error', () => {
    //console.error(error);
    isListening.value = false;
    statusMessage.value = 'An error occurred. Please try again.';
  });
// Listen to Vapi events for messages
vapi.on("message", (message) => {
  if (message.type === "transcript") {
    // Determine the speaker (user or tutor) based on role
    const speaker = message.role === "user" ? "You" : "Jane (English Tutor)";
    
    // Add the message to the conversation
    messages.value.push({
      speaker,
      content: message.transcript,
    });
  }
});

});

// Vapi assistant options
const assistantOptions = {
  name: 'English Tutor',
  firstMessage: "Hello! I'm here to help you practice English speaking.",
  transcriber: {
    provider: 'deepgram',
    model: 'nova-2',
    language: 'en-US',
  },
  voice: {
    provider: 'playht',
    voiceId: 'jennifer',
  },
  model: {
    provider: 'openai',
    model: 'gpt-4',
    messages: [
      {
        role: 'system',
        content: `You are an English-speaking tutor. Your role is to:
1. Provide feedback on pronunciation and sentence structure in a friendly and supportive manner.
2. Let students choose a topic or theme for the day to practice.
3. Share vocabulary and expressions related to the chosen topic.
4. Keep your responses short, simple, and engaging while encouraging the student to speak more.`,
      },
    ],
  },
};


// Use Vue Router to navigate to the billing page
const router = useRouter();

// Redirect to the billing page
const redirectToBilling = () => {
  router.push('/billing');
};
</script>

<style scoped>
/* General Layout Styles */
.tutor-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.icon-container {
  margin-bottom: 20px;
}

.big-icon {
  font-size: 80px;
  color: #4ebf66;
  cursor: pointer;
}

.big-icon:hover {
  color: #25b09b;
  transform: scale(1.1);
  transition: 0.3s;
}

.disabled-icon {
  color: #ccc; /* Light gray to indicate it is disabled */
  cursor: not-allowed;
}

.button-container {
  margin: 20px;
}

.stop-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #d9534f;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.stop-button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

.stop-button:hover:not(:disabled) {
  background-color: #c9302c;
}

.text-container {
  max-width: 600px;
}

.description-text {
  font-size: 1.2rem;
  color: #3e4450;
}

.verification-status {
  font-size: 1rem;
  color: #28a745;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-button:hover {
  background-color: #0056b3;
}

/* Conversation Container Styles */
.conversation-container {
  max-width: 600px;
  margin-top: 20px;
  text-align: left;
  background: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  /* Fixed height and scrolling */
  max-height: 300px; /* Adjust height as needed */
  overflow-y: auto;
  overflow-x: hidden;
}

.conversation-container::-webkit-scrollbar {
  width: 8px;
}

.conversation-container::-webkit-scrollbar-thumb {
  background-color: #4ebf66;
  border-radius: 4px;
}

.conversation-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.conversation-message {
  margin: 5px 0;
  line-height: 1.5;
}

.conversation-message strong {
  color: #4ebf66;
}

/* Media Queries for Responsive Design */
@media (max-width: 600px) {
  .big-icon {
    font-size: 12vw; /* Make icons larger on smaller screens */
  }

  .stop-button {
    width: 100%;
    font-size: 18px; /* Adjust font size for smaller screens */
  }

  .description-text {
    font-size: 1rem; /* Slightly smaller text for mobile */
  }

  .conversation-message {
    font-size: 0.9rem; /* Adjust conversation message font */
  }
}

</style>
