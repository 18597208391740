<template>
  <div class="main-content-container">
    <div v-if="loading" class="columns-container-spinner">
			<p class="container-spinner"></p>
		</div>
    <template v-else>
    <div class="title">TRAIN WITH DATA</div>
    <div class="upload-view">
      <div class="upload-item model-name ml-2">Model Geo npt</div>
      <div class="upload-item ml-2"></div>
      <div class="upload-item ml-2"></div>
      <div class="upload-item ml-2"></div>
      <div class="upload-item ml-2 merged-item-up">
        <div class="interface-upload ml-2">
          <div
            class="iu1"
            @click="toggleClicked('iu1')"
            :class="{ clicked: clicked === 'iu1' }"
          >
            Upload File
          </div>
          <div
            class="iu2"
            @click="toggleClicked('iu2')"
            :class="{ clicked: clicked === 'iu2', incomplete: clicked === 'iu2' }"
            :style="{ whiteSpace: 'nowrap' }"
          >
            Enter manually
          </div>
          <div
            class="iu3"
            @click="toggleClicked('iu3')"
            :class="{ clicked: clicked === 'iu3', incomplete: clicked === 'iu3' }"
            :style="{ whiteSpace: 'nowrap' }"
          >
            Scan document
          </div>
        </div>
      </div>

      <div class="upload-item picker merged-icon ml-2" @click="openFilePicker">
        <input
          type="file"
          ref="fileInput"
          class="input-file"
          @change="handleFileUpload"
          accept=".csv,.xlsx,.xls"
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22.73"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M14.2 17.1333H18.6C21.0303 17.1333 23 15.1636 23 12.7333C23 10.3031 21.0303 8.33333 18.6 8.33333H17.1333V6.13333C17.1333 3.29827 14.8351 1 12 1C9.16493 1 6.86667 3.29827 6.86667 6.13333V6.86667H6.13333C3.29827 6.86667 1 9.16493 1 12C1 14.8351 3.29827 17.1333 6.13333 17.1333H9.8"
            stroke="black"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linejoin="round"
          />
          <path
            d="M12 23.7334V9.80005"
            stroke="black"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linejoin="round"
          />
          <path
            d="M7.6 14.2L12 9.80005L16.4 14.2"
            stroke="black"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div class="upload-item merged-picker" @click="openFilePicker">
        <span class="add-files">Add files or drop files here</span>
      </div>

      <div class="upload-item mb-5 grid grid-cols gap-6">
        <div class="grid-col-span-3 custom-width ml-2 scroll-container">
          <div
            class="grid grid-cols-1 upload-item-expanded-file files-list gap-2"
            v-for="file in files"
            :key="file.id"
          >
            <div class="filename">{{ truncateText(file.name) }}</div>
            <div class="filesizw">{{ truncateText(file.path) }}</div>
            <div class="filesizw">{{ formatFileSize(file.size) }}</div>
            <div class="status">
              <div class="status-text">{{ file.status }}</div>
              <div class="delete" @click="deleteFile(file.id)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                >
                  <path
                    d="M1 1.11084L12.0313 12.1421"
                    stroke="#3E4450"
                    stroke-linecap="round"
                  />
                  <path
                    d="M1 12.0312L12.0313 0.999984"
                    stroke="#3E4450"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div
          class="grid-col-span-1 d-flex flex-column align-items-end mr-4 ml-4 mb-2"
          v-if="filepolished"
        >
          <div class="filename font-bold mb-2">Information </div>
          <!-- <div class="filename"> System :{{ fileinfo.data.file_id.system }}</div> -->
          <div class="filename">
            <!-- Directive :{{ fileinfo.data.file_id.user_directive }} -->
          </div>
          <!-- <div class="filename"> Model :{{ fileinfo.data.file_id.model_type }}</div> -->
        </div>
      </div>
    </div>
    <div class="training-div">
      <button class="training" @click="train">
        <template v-if="loading">
          <ProgressSpinner class="spinner" />
        </template>
        <template v-else>
          <span v-if="!trainingStarted" class="font-bold">Start training with datas</span>
          <span v-else class="font-bold">
            Training started
            <i class="fas fa-spinner fa-spin" style="margin-left: 5px;"></i>
          </span>
        </template>
      </button>
    </div>
    <!-- Modal and other components -->
    <Modal @submit="submitModal" :columns="columns" :modalTitle="modalTitle" />
    <modal-training-data :show-modal="showModalTraining" @close-modal="closeModal" />
  </template>
  </div>
</template>

<script>
import axios from "axios";
import { retrieveToken } from "../services/tokenService";
import Modal from "../components/upload-modal.vue";
import { mapActions } from "vuex";
import ModalTrainingData from "../components/training/ModalTrainingData.vue";
import ProgressSpinner from 'primevue/progressspinner';

export default {
  name: "BaseContent",

  data() {
    return {
      fileUploaded: false,
      showModalTraining: false,
      clicked: "iu1", // Track which item is clicked
      files: [],
      file: null,
      fileUploading: false,
      uploadProgress: 0,
      modalTitle: "Your Modal Title", // Set the title of the modal
      columns: [],
      loading: false,

    };
  },

  computed: {
    rawFileInfo() {
      return this.$store.state.rawFileInfo;
    },

    apiKey() {
      return this.$store.state.api_key;
    },
    showModal() {
      return this.$store.state.showModalColumns;
    },
    fileinfo() {
      return this.$store.state.fileInfo;
    },
    filepolished() {
      return this.$store.state.filepolished;
    },
    trainingStarted() {
      return this.$store.state.trainingStarted;
    },
    
  },
   mounted() {
    this.setActiveMenu('fine-tuning');
   },

  methods: {
    ...mapActions(["setTrainingStarted", "setActiveMenu"]),
    goToStep(step) {
      this.$store.commit("setStep", step);
    },
  
    openModal() {
      this.showModalTraining = true;
    },
    closeModal() {
      this.showModalTraining = false;
    },
    openFilePicker() {
      this.$refs.fileInput.click();
    },
    ...mapActions(["setFilePolished"]),
    ...mapActions(["setRawFileInfo"]),
    ...mapActions(["openModalColumn"]),
    ...mapActions(['setFileUri']),
    ...mapActions(['setFileId']),



    truncateText(text) {
      const maxLength = 10;
      if (text.length <= maxLength) {
        return text;
      }
      return text.substring(0, maxLength) + "...";
    },
    deleteFile(fileId) {
      this.files = this.files.filter((file) => file.id !== fileId);
    },

    handleFileUpload(event) {
      const files = event.target.files;
      if (files.length > 0) {
        this.uploadFile(files);
      }
    },

    formatFileSize(sizeInBytes) {
      const fileSizeInKB = sizeInBytes / 1024;
      return fileSizeInKB.toFixed(2) + " KB";
    },

  async train() {
  this.loading = true;
  try {
    const s3KeyJson = localStorage.getItem('s3Key');

    const apiKeyJson = localStorage.getItem('apiKey');

    let  api_Key = JSON.parse(apiKeyJson);

// Parse the JSON string back to its original format
    const s3Key = JSON.parse(s3KeyJson);
    console.log('api key',this.api_Key)
    let apiKey = api_Key;
    let fileUrl = s3Key; // Assuming this is the file URL
    const baseUrl = process.env.VUE_APP_BASE_URL;


    console.log('API key', apiKey);
    console.log('File URL', fileUrl);

    if (!apiKey || !fileUrl) {
      console.error('API key or file URL is missing');
      this.loading = false;
      return;
    }

    // Send the fine-tuning request
    const response = await axios.post(`${baseUrl}/fine-tune-language-model`, {
      fileUrl: fileUrl,
      apiKey: apiKey
    });

    const jobId = response.data.jobId;
    console.log('Fine-tuning started. Job ID:', jobId);

    // Poll the job status
    const checkJobStatus = async () => {
      try {
        // Retrieve and sanitize the API key
        const apiKeyUser = localStorage.getItem('apiKey')?.replace(/^"(.*)"$/, '$1').trim();
        console.log('Sanitized API Key:', apiKeyUser);

        const baseUrl = process.env.VUE_APP_BASE_URL;


        if (!apiKeyUser) {
            throw new Error('API key not found');
        }

        const statusResponse = await axios.get(`${baseUrl}/check-job-status/${jobId}`, {
            params: {
                apikey_user: apiKeyUser
            }
        });

        const statusData = statusResponse.data;
        console.log('Job Status:', statusData);

        if (statusData.status === 'succeeded') {

          console.log('Fine-tuning succeeded. Model ID:', statusData.fineTunedModelId);
          this.setActiveMenu('test-endpoint');
          this.$router.push('/test-endpoint');
          localStorage.setItem('fineTunedModelId', statusData.fineTunedModelId);

          this.$store.commit('updateCurrentComponent', 'testingphase');
          this.goToStep(3);
          this.loading = false;
        } else if (statusData.status === 'failed') {
          console.error('Fine-tuning failed. Error:', statusData.errorMessage);
          this.loading = false;
        } else {
          // If the job is not yet complete, poll again after a delay
          setTimeout(checkJobStatus, 5000); // Poll every 5 seconds
        }
      } catch (error) {
        console.error('Error checking job status:', error);
        this.loading = false;
      }
    };

    // Start polling the job status
    checkJobStatus();

  } catch (error) {
    console.error('Error:', error);
    this.loading = false;
  }
}
,

    toggleClicked(item) {
      // Toggle clicked state based on the clicked item
      this.clicked = item;
    },
    mockUploadFile() {
      console.log("Mock upload in development mode");
      setTimeout(() => {
        this.fileUploading = false;
        this.uploadProgress = 100;
        this.fileUploaded = true;
        this.columns = ['Outcome', 'Possible Cause', 'Final Outcome'];
        this.openModal();
      }, 1000); // Simulate async delay
    },

    async uploadFile(files) {
  console.log(files);

  if (files.length === 0) return;

  const file = files[0];
  this.files = [{
    id: file.name, // or another unique property
    name: file.name,
    size: file.size,
    type: file.type,
    path: file.webkitRelativePath || file.name,
    status: 'Ready for upload'
  }];
  this.file = file;
  const apiKeyData = localStorage.getItem('apiKey');
  const apiKey = apiKeyData ? JSON.parse(apiKeyData) : null;

if (!apiKey) {
  console.error('API Key is missing');
  // Handle missing API key case
}

  const token = retrieveToken();

  if (!token || !this.file) {
    console.log('eto');
    this.fileUploading = false;
    return;
  }

  const userData = localStorage.getItem('userId');
  const userId = userData ? JSON.parse(userData).userId : null;

  const formData = new FormData();
  formData.append("user_id", userId);
  formData.append("file", this.file);
  formData.append("path", this.file.webkitRelativePath || this.file.name);
  this.loading = true;

  try {
    const baseUrl = process.env.VUE_APP_BASE_URL;

    console.log('eto');
    const response = await axios.post(`${baseUrl}/upload-csv/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${apiKey}`,
      },
      timeout: 60000 * 30, // Adjust the timeout as needed
      onUploadProgress: () => {
        // Calculate the upload progress percentage
        this.uploadProgress = 75;
        // Update the UI with the upload progress if needed
      },
    });
    this.fileUploading = false;
    this.uploadProgress = 0;
    this.openModalColumn()

    console.log('voidi le data ', response.data);
    this.fileUploaded = true;
    this.columns = response.data.column_headings;
    this.setRawFileInfo(response.data);
    console.log('reponse data file uri',response.data.file_uri)
    this.setFileUri(response.data.file_uri)
    this.setFileId(response.data.file_id)
    this.loading = false;

  } catch (error) {
    this.fileUploading = false;
    this.uploadProgress = 0;
    this.loading = false;
  }
}
,
  },

  components: {
    Modal,
    ModalTrainingData,
    ProgressSpinner
  }
};

  
</script>

<style scoped>
.upload-view {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  border-radius: 20px;
  background: #f4f4f4;
  padding: 20px;
  overflow-y: auto; /* Change to auto to handle scrollability */
}

.upload-item {
  width: 90%;
  height: fit-content;
  margin-bottom: 1rem;
}

.title {
  margin-bottom: 2rem;
  margin-top: 0.5rem;
  text-align: left;
  color: #3e4450;
  text-indent: 0;
  text-align: justify;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.model-name {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.merged-item-up {
  width: calc(100% - 30px);
  border: 1px solid rgba(20, 200, 113, 0.15);
  background: #fff;
  border-radius: 27.5px;
  display: flex;
  justify-content: center;
  align-items: stretch;
}

@media (max-width: 768px) {
  .merged-item-up {
    padding: 10px 40px;
    justify-content: center;
    width: 100%;
  }
}

.interface-upload .item {
  flex: 1;
  text-align: center;
  cursor: pointer;
  padding: 10px;
  margin: 5px;
  transition: background-color 0.3s ease;
}

@media (max-width: 768px) {
  .interface-upload {
    flex-direction: column;
    padding: 20px 50px;
  }

  .interface-upload .item {
    margin-bottom: 10px;
  }
}

.interface-upload {
  display: flex;
  justify-content: space-between;
  margin-right: 1rem;
  border-radius: 27.5px;
  justify-content: center;
  align-items: center;
}

.interface-upload > div {
  flex: 1;
  text-align: center;
  padding: 10px 40px;
  border-radius: 20px;
}

.interface-upload > div:not(:last-child) {
  margin-right: 2rem;
}

.clicked {
  border-radius: 20px;
  background: #14c871;
  color: white;
}

.incomplete {
  background: grey;
  border-radius: 20px;
  color: white;
}

.merged-icon,
.merged-picker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: calc(50% - 40px);
  background: #fff;
  margin-bottom: 50px;
  margin-top: 50px;
  height: 20vh;
}

.merged-picker {
  align-items: center;
  flex-grow: 1;
}

.add-files {
  color: #14c871;
}

.merged-icon {
  flex-direction: row-reverse;
  margin-bottom: 10px;
  height: 20vh;
}

.merged-icon > svg {
  margin-left: auto;
  margin-right: 20px;
}

.files-list {
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 4px;
  margin-left: 10px;
}

.files-list > * {
  flex: 1;
}

.status {
  display: flex;
  gap: 30px;
  width: 50%;
  flex-direction: row;
  justify-content: center;
}

.delete {
  width: 50%;
  display: flex;
  justify-content: center;
}

.delete > svg {
  text-align: center;
}

.status-text {
  width: 50%;
}

.start-train {
  width: 40%;
  border-radius: 25px;
  margin-left: 10px;
  margin-top: 20px;
}

.training {
  border-radius: 25px;
  background: #14c871;
  border-color: #14c871;
  padding: 10px 15px;
}

@media (max-width: 768px) {
  .training {
    white-space: nowrap;
  }
}

@media (max-width: 400px) {
  .training {
    white-space: nowrap;
    justify-content: center;
  }
}

.main-content-container {
  margin-left: 2rem;
  overflow-y: auto; /* Ensure vertical scrollbar appears when needed */

}

.input-file {
  position: absolute;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
}

.upload-item-expanded-file {
  background-color: rgba(20, 200, 113, 0.15);
  padding: 10px 20px;
  border-radius: 10px;
  color: #000000;
}

.upload-item-expanded-file .status-text,
.upload-item-expanded-file .filename,
.upload-item-expanded-file .filesize {
  color: #000000;
}

.delete svg path {
  stroke: #000000;
}

.training-div {
  margin-left: 30px;
  margin-bottom: 20px;
  margin-top: 10px;
  top: -60px;
  position: relative;
}

.custom-width {
  margin-top: 10px;
  width: 75%;
  flex-wrap: wrap;
}

@media (max-width: 576px) {
  .custom-width {
    width: 100%;
    margin-left: 0;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .custom-width {
    width: 70%;
    margin-left: 10px;
  }
}

@media (min-width: 768px) {
  .custom-width {
    width: 75%;
    margin-left: 10px;
  }
}

.scroll-container {
  max-height: 300px;
  overflow-y: auto; /* Change to auto to handle scrollability */
}

.spinner {
  width: 20px;
  height: 20px;
  justify-content: center;
}

.columns-container-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.container-spinner {
  --d: 50px;
  width: 20px;
  margin-top: 10%;
  height: 20px;
  border-radius: 50%;
  color: #25b09b;
  box-shadow: 
    calc(1*var(--d)) calc(0*var(--d)) 0 0,
    calc(0.707*var(--d)) calc(0.707*var(--d)) 0 1px,
    calc(0*var(--d)) calc(1*var(--d)) 0 2px,
    calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 3px,
    calc(-1*var(--d)) calc(0*var(--d)) 0 4px,
    calc(-0.707*var(--d)) calc(-0.707*var(--d)) 0 5px,
    calc(0*var(--d)) calc(-1*var(--d)) 0 6px;
  animation: l27 1s infinite steps(8);
}

@keyframes l27 {
  100% { transform: rotate(1turn) }
}
</style>
