export async function getModels() {
	const response = await fetch('https://prod.islandaiforge.us/get_all_models', {
		method: 'POST'
	});

	if (!response.ok) {
		console.log(response)
	}



	const data = await response.json();
	console.log(data)

	data.sort((a, b) => {
		if (a.installed && !b.installed) {
			return -1;
		}
		if (!a.installed && b.installed) {
			return 1;
		}
		return 0;
	});

	return data;
}

export async function requestInstall(data) {
	const  response = await fetch(`${process.env.VUE_APP_BASE_URL}/request_install`, {data}, {
		method: 'POST'
	});

	return response;
}

// export async function getModels() {
// 	const response = await fetch(`/api/get_all_models`, {
// 		method: 'GET'
// 	});
  
// 	if (!response.ok) {
// 		throw new Error("Error fetching models");
// 	}
  
// 	const data = await response.json();
// 	return data.models;
// }

export async function getFineTuning() {
	const response = await fetch(`/api/fine-tuning`, {
		method: 'GET'
	});
  
	if (!response.ok) {
		throw new Error("Error fetching fine tuning");
	}
  
	const data = await response.json();
	return data.finetunings;
}
  

export async function getAllDataManagement() {
	const response = await fetch(`api/data_managements`, {
		method: 'GET'
	});

	if(!response.ok) {
		throw new Error('Error get all data management')
	}

	const data =  await response.json();
	console.log(data);
	return data.managements;
}