<template>
    <div class="container">
      <div v-if="!isEligible" class="ineligible-message">
        <h3>Access Denied</h3>
        <p>You are not eligible to use the Medical Assistant. Please contact support for more information.</p>
      </div>
      <div v-else>
        <h2>Medical Assistant</h2>

        <div class="action-buttons">
        <button @click="createAssistant" :disabled="!isEligible || hasCall">Create Assistant</button>
        <button @click="buyNumbers" :disabled="!isEligible">Buy Numbers</button>
      </div>

      <div v-if="showCreateAssistantModal" class="modal-overlay" @click="closeModal">
      <div class="modal" @click.stop>
        <button class="close-button" @click="closeModal">X</button>

        <h2>Create Assistant</h2>
        <form @submit.prevent="submitAssistant">
          <div class="form-group">
          <label for="language">Language:</label>
          <div class="custom-select">
            <input 
              v-model="newAssistant.language" 
              id="language" 
              type="text" 
              placeholder="Select or type to filter..."
              @input="filterLanguages"
              @focus="showDropdown"
              @blur="hideDropdown"
              required
            />
            <div class="custom-select-dropdown" :class="{'show': dropdownVisible}">
              <div 
                v-for="lang in filteredLanguages" 
                :key="lang.value" 
                @click="selectLanguage(lang.value)"
                :data-value="lang.value"
              >
                {{ lang.text }}
              </div>
            </div>
          </div>
        </div>
          <div class="form-group">
            <label for="firstMessage">First Message:</label>
            <textarea v-model="newAssistant.firstMessage" id="firstMessage" required></textarea>
          </div>
         
          <button type="submit">Create</button>
        </form>
      </div>
    </div>
  
        <div v-if="!hasCall" class="no-call-message">
          <p>No active calls available at the moment.</p>
        </div>
  
        <div v-else>
          <p>Playing active call audio...</p>
          <audio ref="audioPlayer" controls autoplay>
            <source :src="listenUrl" type="audio/mpeg" />
            Your browser does not support the <code>audio</code> element.
          </audio>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'MedicalAssistant',
    data() {
      return {
        isEligible: false,
        listenUrl: '',
        hasCall: false,
        showCreateAssistantModal: false,
      newAssistant: {
        language: '',
        firstMessage: '',
        file: null
      },
      languageFilter: '',
      languages: [
        { value: 'bg', text: 'Bulgarian' },
        { value: 'ca', text: 'Catalan' },
        { value: 'cs', text: 'Czech' },
        { value: 'da', text: 'Danish' },
        { value: 'da-DK', text: 'Danish (Denmark)' },
        { value: 'de', text: 'German' },
        { value: 'de-CH', text: 'German (Switzerland)' },
        { value: 'el', text: 'Greek' },
        { value: 'en', text: 'English' },
        { value: 'en-AU', text: 'English (Australia)' },
        { value: 'en-GB', text: 'English (UK)' },
        { value: 'en-IN', text: 'English (India)' },
        { value: 'en-NZ', text: 'English (New Zealand)' },
        { value: 'en-US', text: 'English (US)' },
        { value: 'es', text: 'Spanish' },
        { value: 'es-419', text: 'Spanish (Latin America)' },
        { value: 'es-LATAM', text: 'Spanish (Latin America - LATAM)' },
        { value: 'et', text: 'Estonian' },
        { value: 'fi', text: 'Finnish' },
        { value: 'fr', text: 'French' },
        { value: 'fr-CA', text: 'French (Canada)' },
        { value: 'hi', text: 'Hindi' },
        { value: 'hi-Latn', text: 'Hindi (Latin Script)' },
        { value: 'hu', text: 'Hungarian' },
        { value: 'id', text: 'Indonesian' },
        { value: 'it', text: 'Italian' },
        { value: 'ja', text: 'Japanese' },
        { value: 'ko', text: 'Korean' },
        { value: 'ko-KR', text: 'Korean (South Korea)' },
        { value: 'lt', text: 'Lithuanian' },
        { value: 'lv', text: 'Latvian' },
        { value: 'ms', text: 'Malay' },
        { value: 'multi', text: 'Multilingual' },
        { value: 'nl', text: 'Dutch' },
        { value: 'nl-BE', text: 'Dutch (Belgium)' },
        { value: 'no', text: 'Norwegian' },
        { value: 'pl', text: 'Polish' },
        { value: 'pt', text: 'Portuguese' },
        { value: 'pt-BR', text: 'Portuguese (Brazil)' },
        { value: 'ro', text: 'Romanian' },
        { value: 'ru', text: 'Russian' },
        { value: 'sk', text: 'Slovak' },
        { value: 'sv', text: 'Swedish' },
        { value: 'sv-SE', text: 'Swedish (Sweden)' },
        { value: 'ta', text: 'Tamil' },
        { value: 'taq', text: 'Taqbaylit (Kabyle)' },
        { value: 'th', text: 'Thai' },
        { value: 'th-TH', text: 'Thai (Thailand)' },
        { value: 'tr', text: 'Turkish' },
        { value: 'uk', text: 'Ukrainian' },
        { value: 'vi', text: 'Vietnamese' },
        { value: 'zh', text: 'Chinese' },
        { value: 'zh-CN', text: 'Chinese (China)' },
        { value: 'zh-Hans', text: 'Chinese (Simplified)' },
        { value: 'zh-Hant', text: 'Chinese (Traditional)' },
        { value: 'zh-TW', text: 'Chinese (Taiwan)' }
      ],
      };
    },
    created() {
      this.checkEligibility()
      
    },
    computed: {
    filteredLanguages() {
      const filterText = this.newAssistant.language.toLowerCase();
      return this.languages.filter(lang => 
        lang.text.toLowerCase().includes(filterText) || 
        lang.value.toLowerCase().includes(filterText)
      );
    }
  },
    methods: {
      showDropdown() {
      this.dropdownVisible = true;
    },
    hideDropdown() {
      setTimeout(() => {
        this.dropdownVisible = false;
      }, 200); // Small delay to allow for clicks on options
    },
    selectLanguage(value) {
      // Update newAssistant.language with the selected language code
      this.newAssistant.language = value;
      this.dropdownVisible = false;
    },
      checkEligibility() {
        const baseUrl = process.env.VUE_APP_BASE_URL;
  
  
        const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
  
        axios
          .get(`${baseUrl}/check-eligibility`, {
            params: { userId } // Send userId as a query parameter
          })
          .then(response => {
            this.isEligible = response.data.status;
            if (this.isEligible) {
              this.fetchActiveCall();
            }
          })
          .catch(error => {
            console.error('Error checking eligibility:', error);
            this.isEligible = false;
          });
      },
  
      fetchActiveCall() {
        const apiKey = localStorage.getItem('apiKey');
        const baseUrl = process.env.VUE_APP_BASE_URL;
  
        axios
          .get(`${baseUrl}/active-call`, {
            headers: { Authorization: `Bearer ${apiKey}` }
          })
          .then(response => {
            const callData = response.data;
  
            if (callData && callData.listenUrl) {
              this.listenUrl = callData.listenUrl;
              this.hasCall = true;
              this.playCallAudio();
            } else {
              this.hasCall = false;
            }
          })
          .catch(error => {
            console.error('Error fetching active call:', error);
            this.hasCall = false;
          });
      },  
      createAssistant() {
        this.showCreateAssistantModal = true;

    },
    buyNumbers() {
      const baseUrl = process.env.VUE_APP_BASE_URL;
      const apiKey = localStorage.getItem('apiKey');

      axios.post(`${baseUrl}/buy-numbers`, {}, {
        headers: { Authorization: `Bearer ${apiKey}` }
      })
      .then(response => {
        console.log('Numbers purchased:', response.data);
        // Handle success, perhaps show a message or update state
      })
      .catch(error => {
        console.error('Error buying numbers:', error);
        // Handle error, show user feedback
      });
    }
  ,
      playCallAudio() {
        const audioPlayer = this.$refs.audioPlayer;
        if (audioPlayer) {
          audioPlayer.src = this.listenUrl;
          audioPlayer.play().catch(error => console.error('Audio playback failed:', error));
        }
      },
      closeModal() {
      this.showCreateAssistantModal = false;
      this.newAssistant = {
        language: '',
        firstMessage: '',
        file: null
      };
    },
    submitAssistant() {
      const formData = new FormData();
      formData.append('language', this.newAssistant.language);
      formData.append('firstMessage', this.newAssistant.firstMessage);
      if (this.newAssistant.file) {
        formData.append('knowledgeBase', this.newAssistant.file);
      }

      const baseUrl = process.env.VUE_APP_BASE_URL;
      const apiKey = localStorage.getItem('apiKey');

      axios.post(`${baseUrl}/submit-assistant`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${apiKey}`
        }
      })
      .then(response => {
        console.log('Assistant created:', response.data);
        this.closeModal();
        // Optionally, you might want to refresh some data here or show a success message
      })
      .catch(error => {
        console.error('Error creating assistant:', error);
        // Handle error, show user feedback
      });
    }
    }
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .ineligible-message {
    text-align: center;
    background-color: #f8d7da;
    padding: 20px;
    border-radius: 5px;
    color: #721c24;
    margin-top: 20px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .no-call-message {
    text-align: center;
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 5px;
    color: #555;
    margin-top: 20px;
  }
  
  audio {
    width: 100%;
    margin-top: 20px;
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    max-width: 500px;
  }

  .form-group {
    margin-bottom: 15px;
  }

  label {
    display: block;
    margin-bottom: 5px;
  }

  input[type="text"], textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  input[type="file"] {
    margin-top: 5px;
  }
  /* New styles for the custom dropdown */
.custom-select {
  position: relative;
  display: inline-block;
  width: 100%;
}

.custom-select input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}

.custom-select::after {
  content: '\25BC'; /* Down arrow */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #555;
}

.custom-select input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
/* New styles for the dropdown list */
.custom-select-dropdown {
  position: absolute;
  top: 100%;  /* Position below the input */
  left: 0;  /* Align with the input's left edge */
  width: 100%;
  background: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;  /* Ensure it's above other elements */
  display: none;  /* Hidden by default */
}

.custom-select-dropdown.show {
  display: block;  /* Show when class 'show' is added */
}

.custom-select-dropdown option {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.custom-select-dropdown option:hover {
  background-color: #f1f1f1;
}

.custom-select-dropdown option:last-child {
  border-bottom: none;
}
  </style>
  