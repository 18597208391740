<template>
	<main class="app-main-content">
		<div v-if="loading">
				<ProgressSpinner class="spinner" />
			</div>
		<BaseInput v-else class="mt-3 mb-6" @button-clicked="sendRequest()" v-model="requirement" placeholder="Have an idea? Let us know" :is-valid="isValid">
			<svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
				<path d="M3.85291 1H16.8036V13.8571" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M1.69458 16L16.8037 1" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
			</svg>
		</BaseInput>
		<div v-if="loading" class="columns-container-spinner">
			<p class="container-spinner"></p>
		</div>
		<div v-else  class="columns-container">
			<div class="steps" :style="{ width: '25%', maxWidth: '600px' }">
				<div class="column">
					<div class="row header">
						<SvgIcon label="1" :active="currentStep" :stepNumber="1">1</SvgIcon>
					</div>
					<div class="actions">
						<div class="row header-label">STEP</div>
						<div class="row content">Model to use</div>
					</div>
				</div>

				<div class="column">
					<div class="row header"></div>
					<div class="actions">
						<div class="row header">
							<svg xmlns="http://www.w3.org/2000/svg" width="4" height="83" viewBox="0 0 4 83" fill="none">
								<path d="M2 2L2 81" stroke="#858EA1" stroke-width="3" stroke-linecap="round" />
							</svg>
						</div>
						<div class="row content"></div>
					</div>
				</div>
				<!-- Repeat columns as needed -->
				<div class="column">
					<div class="row header">
						<SvgIcon label="2" :active="currentStep" :stepNumber="2">2</SvgIcon>
					</div>
					<div class="actions">
						<div class="row header-label">Step</div>
						<div class="row content">Train with data</div>
					</div>
				</div>
				<div class="column">
					<div class="row header"></div>
					<div class="actions">
						<div class="row header-label">
							<svg xmlns="http://www.w3.org/2000/svg" width="4" height="83" viewBox="0 0 4 83" fill="none">
								<path d="M2 2L2 81" stroke="#858EA1" stroke-width="3" stroke-linecap="round" />
							</svg>
						</div>
						<div class="row content"></div>
					</div>
				</div>
				<div class="column">
					<div class="row header">
						<SvgIcon label="3" :active="currentStep" :stepNumber="3">3</SvgIcon>
						<!-- <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
							<circle cx="17.5" cy="17.5" r="17.5" fill="#858EA1" />
							<text x="50%" y="50%" text-anchor="middle" fill="white" dy=".3em" font-size="15">3</text>
						</svg> -->
					</div>

					<div class="actions">
						<div class="row header-label">Step</div>
						<div class="row content">Test</div>
					</div>
				</div>

				<div class="column">
					<div class="row header"></div>
					<div class="actions">
						<div class="row header-label">
							<svg xmlns="http://www.w3.org/2000/svg" width="4" height="83" viewBox="0 0 4 83" fill="none">
								<path d="M2 2L2 81" stroke="#858EA1" stroke-width="3" stroke-linecap="round" />
							</svg>
						</div>
						<div class="row content"></div>
					</div>
				</div>

				<div class="column">
					<div class="row header">
						<SvgIcon label="4" :active="currentStep" :stepNumber="4">4</SvgIcon>
						<!-- <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
							<circle cx="17.5" cy="17.5" r="17.5" fill="#858EA1" />
							<text x="50%" y="50%" text-anchor="middle" fill="white" dy=".3em" font-size="15">4</text>
						</svg> -->
					</div>

					<div class="actions">
						<div class="row header-label">Step</div>
						<div class="row content">Deploy</div>
					</div>
				</div>
			</div>
			<component
				:is="currentComponent"
				:recommendedModels="recommendedModels"
				v-if="currentComponent !== 'UploadFile' && currentComponent !== 'testingphase'" 
			/>
			<!-- Render the UploadFile component conditionally -->
			<UploadFile v-else-if="currentComponent === 'UploadFile'" />
			<!-- Render the TestingPhase component conditionally -->
			<TestingPhase v-else-if="currentComponent === 'testingphase'" />
			<DeployContent v-else-if="currentComponent === 'deployContent'" />
		</div>
	</main>
</template>

<script>
	import { ref, computed } from 'vue';
	import { useStore } from 'vuex';

	import '@fortawesome/fontawesome-free/css/all.css';
	import BaseContent from '../maincontent/BaseContent.vue'; // Adjust the import path as necessary
	import ModelToUseContent from '../maincontent/ModelToUseContent.vue';
	import UploadFile from '../maincontent/UploadFile.vue';
	import TestingPhase from '../maincontent/testModel.vue';
	import DeployContent from '../maincontent/DeployContent.vue';
	import ProgressSpinner from 'primevue/progressspinner';
	// import axios from 'axios';
	import SvgIcon from '../tools/SvgIcons.vue';
	import BaseInput from './BaseInput';
	import { recommendAiModel } from "../services/modelrecommended.service.js";

	
	
	export default {
  methods: {
  },
		name: 'AppMainContent',
		props: {
			label: String,
			active: Boolean
		},
		setup() {
			const store = useStore();
			const currentStep = computed(() => {
				console.log('Accessing currentStep:', store.state.currentStep);
				return store.state.currentStep;
			});

			console.log('current step', currentStep);
			const currentComponent = computed(() => store.state.currentComponent);
			const stepsWidth = computed(() => store.state.stepsWidth);
			const requirement = ref(''); // Define requirement as a reactive property
			const loading = ref(false);
			const isValid = ref(true);
			// const recommendedModels = store.state.recommendedModels || [];
			const sendRequest = async() => {
				if (!requirement.value.trim()) {
						isValid.value = false;
						return;
					}
				loading.value = true;
				isValid.value = true;

				try {
					const response = await recommendAiModel(requirement.value);
					console.log('Response:', response);
					if (Array.isArray(response)) {
					const truncatedResults = response.map(model => console.log(model));
					console.log('Truncated Results:', truncatedResults);
					store.commit('updateRecommendedModels', response);

					} else {
						console.error('Response is not an array:', response);
					}
					store.commit('updateCurrentComponent', 'ModelToUseContent');
					store.commit('updateStepsWidth', '20%');
				} catch (error) {
					console.error('Error:', error);
				} finally {
					loading.value = false;
				}

				// const baseUrl = process.env.VUE_APP_BASE_URL; // Accessing environment variable
				// axios
				// 	.post(`${baseUrl}/recommend_ai_model/`, {
				// 		requirement: requirement.value // Use the value from the input field
				// 	})
				// 	.then((response) => {
				// 		console.log('Response:', response); // Log the response
				// 		loading.value = false;
				// 		const truncatedResults = response.data.slice(0, 4);
				// 		store.commit('updateCurrentComponent', 'ModelToUseContent');
				// 		store.commit('updateStepsWidth', '20%');
				// 		store.commit('updateRecommendedModels', truncatedResults); // Update recommended models in the store
				// 	})
				// 	.catch((error) => {
				// 		console.error('Error:', error);
				// 		loading.value = false;
				// 	});
			};
3
			return {
				requirement,
				loading,
				currentStep,
				sendRequest,
				currentComponent,
				stepsWidth,
				isValid
			};
		},
		mounted() {
			// const store = useStore();
			// store.commit('updateCurrentComponent', 'UploadFile');
		},
		components: {
			BaseContent,
			BaseInput,
			ModelToUseContent,
			SvgIcon,
			UploadFile,
			TestingPhase,
			ProgressSpinner,
			DeployContent
		}
	};
</script>
<style scoped>
	.input-wrapper {
		display: flex;
		justify-content: center; /* Center horizontally */
		align-items: center; /* Center vertically */
		position: relative;
		width: 100%;
		margin-bottom: 60px;
	}
	.full-width-input {
		width: 100%;
		padding: 10px 50px 10px 20px;
		border-radius: 32.5px;
		border: 1px solid #d3cece;
		background: #fff;
		box-sizing: border-box;
	}

	.full-width-input:focus {
		border: 1px solid #14c871; /* Custom border color for focus state */
		outline: none; /* Remove default outline */
	}

	.icon-circle {
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		width: 35px;
		height: 35px;
		border-radius: 50%;
		background: #14c871;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.columns-container {
		display: flex;
		flex-direction: row;
		align-items: flex-start; /* Ensures alignment to the left */
		width: 100%;
	}
	.columns-container-spinner {
		display: flex;
		justify-content: center;
		align-items: center; /* Ensures alignment to the left */
		width: 100%;
	}

	.steps {
		display: flex;
		flex-direction: column;
		gap: 20px; /* Space between steps */
		width: 25%;
		margin-left: 50px;
		max-width: 600px; /* Match this with the input-wrapper's max-width */
	}

	.column {
		display: flex;
		flex-direction: row;
		gap: 10px; /* Adds space between the items aligned side by side */
	}

	.action-label {
		color: #3e4450; /* Color for action label text */
		/* leading-trim and text-edge are not directly applicable in CSS, focusing on applicable properties: */
		font-family: Poppins, sans-serif;
		font-size: 13px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
	.row.header-label {
		color: #858ea1; /* Sets the text color */
		font-family: 'Poppins', sans-serif; /* Ensures Poppins is the font family, with a fallback to sans-serif */
		font-size: 12px; /* Sets the font size */
		font-style: normal; /* Ensures the font style is normal */
		font-weight: 400; /* Sets the font weight to normal */
		line-height: normal; /* Sets a normal line height */
		text-transform: uppercase; /* Transforms text to uppercase */

		text-align: left; /* Or any other alignment */
		margin-left: 0rem;
	}
	.row.content {
		color: #3e4450; /* Sets the text color */
		font-family: 'Poppins', sans-serif; /* Ensures Poppins is the font family, with a fallback to sans-serif */
		font-size: 16px; /* Sets the font size */
		font-style: normal; /* Ensures the font style is normal */
		font-weight: 600; /* Sets the font weight to bold */
		line-height: normal; /* Sets a normal line height */
	}
	.spinner {
		width: 30px;
		height: 30px;
		justify-content: start;
		margin-left: 20px;
		--p-progress-spinner-color: green;
	}

	/* HTML: <div class="loader"></div> */
	.container-spinner {
		--d:50px;
		width: 20px;
		margin-top: 10%;
		height: 20px;
		border-radius: 50%;
		color: #25b09b;
		box-shadow: 
			calc(1*var(--d))      calc(0*var(--d))     0 0,
			calc(0.707*var(--d))  calc(0.707*var(--d)) 0 1px,
			calc(0*var(--d))      calc(1*var(--d))     0 2px,
			calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 3px,
			calc(-1*var(--d))     calc(0*var(--d))     0 4px,
			calc(-0.707*var(--d)) calc(-0.707*var(--d))0 5px,
			calc(0*var(--d))      calc(-1*var(--d))    0 6px;
		animation: l27 1s infinite steps(8);
		}
		@keyframes l27 {
		100% {transform: rotate(1turn)}
	}
</style>
