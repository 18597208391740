<template>
	<div class="auth-container">
		<div class="logo-and-form-container">
			<div class="logo-container">
				<img src="@/assets/img/logo.png" alt="Logo" class="logo" />
			</div>
			<div class="form-container">
				<h2>Login
				</h2>
				<!-- Bind the login method to the form's submit event -->
				<div v-if="errorMessage"  class="alert alert-danger" role="alert">
					{{errorMessage}}
				</div>
				<form @submit.prevent="login">
					<div class="form-group email-icon-group">
						<label for="login-email">Email:</label>
						<div class="input-icon-container">
							<input type="email" id="login-email" placeholder="Put here your Email" required />
							<svg class="email-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 19 19" fill="none">
								<path
									d="M13.0277 10.1739C13.9637 9.4415 14.6469 8.43723 14.9822 7.30076C15.3176 6.16428 15.2884 4.95212 14.8987 3.83291C14.5091 2.71369 13.7784 1.74309 12.8082 1.05612C11.8381 0.369161 10.6768 0 9.48584 0C8.29491 0 7.13359 0.369161 6.16345 1.05612C5.19331 1.74309 4.46259 2.71369 4.07295 3.83291C3.6833 4.95212 3.65412 6.16428 3.98946 7.30076C4.32479 8.43723 5.00796 9.4415 5.94394 10.1739C4.34013 10.8129 2.94075 11.8728 1.89499 13.2405C0.849226 14.6083 0.19628 16.2326 0.00575854 17.9404C-0.00803236 18.0651 0.00300573 18.1912 0.0382423 18.3117C0.0734789 18.4321 0.132224 18.5445 0.211124 18.6423C0.370469 18.84 0.602234 18.9666 0.855434 18.9943C1.10863 19.022 1.36253 18.9485 1.56126 18.79C1.75999 18.6316 1.88729 18.4011 1.91514 18.1493C2.12478 16.2933 3.01465 14.5791 4.41473 13.3344C5.81482 12.0896 7.62696 11.4015 9.50493 11.4015C11.3829 11.4015 13.1951 12.0896 14.5951 13.3344C15.9952 14.5791 16.8851 16.2933 17.0947 18.1493C17.1207 18.3826 17.2326 18.598 17.4089 18.7541C17.5852 18.9101 17.8134 18.9957 18.0494 18.9943H18.1544C18.4047 18.9656 18.6334 18.8398 18.7908 18.6442C18.9482 18.4485 19.0214 18.199 18.9946 17.9499C18.8031 16.2373 18.1467 14.6087 17.0955 13.2389C16.0444 11.869 14.6382 10.8095 13.0277 10.1739V10.1739ZM9.48584 9.49974C8.73056 9.49974 7.99224 9.27701 7.36425 8.8597C6.73626 8.44239 6.2468 7.84925 5.95776 7.15529C5.66873 6.46133 5.5931 5.69772 5.74045 4.96102C5.8878 4.22431 6.2515 3.54761 6.78557 3.01648C7.31963 2.48534 8.00007 2.12364 8.74084 1.9771C9.4816 1.83056 10.2494 1.90577 10.9472 2.19321C11.645 2.48066 12.2414 2.96744 12.661 3.59198C13.0806 4.21653 13.3046 4.9508 13.3046 5.70193C13.3046 6.70918 12.9023 7.67516 12.1861 8.38739C11.47 9.09962 10.4986 9.49974 9.48584 9.49974Z"
									fill="#3E4450"
								/>
							</svg>
						</div>
					</div>
					<div class="form-group">
						<label for="login-password">Password:</label>
						<div class="input-icon-container password-input">
							<input :type="showPassword ? 'text' : 'password'" id="login-password" placeholder="Password" required />
							<svg
								@click="togglePasswordVisibility"
								class="password-toggle-icon"
								xmlns="http://www.w3.org/2000/svg"
								width="12"
								height="9"
								viewBox="0 0 22 18"
								fill="none"
							>
								<path
									d="M21.9082 8.55C19.687 3.27375 15.5084 0 11 0C6.49157 0 2.31303 3.27375 0.0918013 8.55C0.0312527 8.69194 0 8.84513 0 9C0 9.15487 0.0312527 9.30806 0.0918013 9.45C2.31303 14.7262 6.49157 18 11 18C15.5084 18 19.687 14.7262 21.9082 9.45C21.9687 9.30806 22 9.15487 22 9C22 8.84513 21.9687 8.69194 21.9082 8.55V8.55ZM11 15.75C7.51421 15.75 4.21536 13.1738 2.31303 9C4.21536 4.82625 7.51421 2.25 11 2.25C14.4858 2.25 17.7846 4.82625 19.687 9C17.7846 13.1738 14.4858 15.75 11 15.75ZM11 4.5C10.1301 4.5 9.27967 4.76392 8.55634 5.25839C7.83302 5.75285 7.26925 6.45566 6.93635 7.27792C6.60344 8.10019 6.51633 9.00499 6.68605 9.87791C6.85576 10.7508 7.27468 11.5526 7.88981 12.182C8.50495 12.8113 9.28868 13.2399 10.1419 13.4135C10.9951 13.5872 11.8795 13.4981 12.6832 13.1575C13.4869 12.8169 14.1739 12.2401 14.6572 11.5001C15.1405 10.76 15.3985 9.89002 15.3985 9C15.3985 7.80653 14.9351 6.66193 14.1102 5.81802C13.2853 4.97411 12.1665 4.5 11 4.5V4.5ZM11 11.25C10.565 11.25 10.1398 11.118 9.77817 10.8708C9.41651 10.6236 9.13463 10.2722 8.96817 9.86104C8.80172 9.4499 8.75817 8.9975 8.84302 8.56105C8.92788 8.12459 9.13734 7.72368 9.44491 7.40901C9.75248 7.09434 10.1443 6.88005 10.571 6.79323C10.9976 6.70642 11.4398 6.75097 11.8416 6.92127C12.2435 7.09157 12.5869 7.37996 12.8286 7.74997C13.0703 8.11998 13.1992 8.55499 13.1992 9C13.1992 9.59674 12.9675 10.169 12.5551 10.591C12.1427 11.0129 11.5833 11.25 11 11.25Z"
									fill="#3E4450"
								/>
							</svg>
						</div>
					</div>
					<div class="form-group">
						<button type="submit" @click="login">
							<span v-if="loading">
								<LoadingSpinner height="20px" width="20px" color="#fff" borderBottomColor="#FF3D00"/>
							</span>
							<span v-else>
								Login
							</span>
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
	import { mapActions } from 'vuex';
	// import { ClipLoader } from 'vue-spinners';
	import LoadingSpinner from './commons/LoadingSpinner.vue';
	import axios from "axios";

	export default {
		name: 'LoginComponent',
		data() {
			return {
				showPassword: false,
				errorMessage: '',
				loading: false
			};
		},
		components: {
            LoadingSpinner
        },
		computed: {
			apiKey() {
				return this.$store.state.api_key;
			}
		},

		methods: {
			...mapActions(['setApiKey']), // Map the setApiKey action

			
			async login() {
  try {
    this.loading = true;
    const email = document.getElementById('login-email').value;
    const password = document.getElementById('login-password').value;
    const baseUrl = process.env.VUE_APP_BASE_URL;

    // Create URLSearchParams for correct encoding of form data
    const formData = new URLSearchParams();
    formData.append('username', email);
    formData.append('password', password);

    // Send the login request using axios with the correct Content-Type
    const response = await axios.post(`${baseUrl}/login/`, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    // Check if the response status is successful (200)
    if (response.status !== 200) {
      console.log('Full error data:', response.data);
      this.errorMessage = 'Connexion error.';
      this.loading = false;
      return;
    }

    const data = response.data;
    console.log('data', data);

    // Assuming the server returns a token in the response
    const token = data.access_token;
    console.log('token', token);

    // Store the token in localStorage
    const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000; // One day from now
    const tokenData = {
      token: token,
      expiresAt: expirationTime,
    };
    localStorage.setItem('token', JSON.stringify(tokenData));
    localStorage.setItem('apiKey', JSON.stringify(data.api_key));

    // Store user ID and plan ID
    localStorage.setItem('userId', data.user_id); // Store user ID as a string
    localStorage.setItem('planId', data.plan_id); // Store plan ID as a string
    this.setApiKey(data.api_key); // Set API key for further use

    console.log('api key login', this.apiKey);
    console.log('user id login', data.user_id);

    // Redirect to the next page
    this.$router.push('/english');
  } catch (error) {
    // Handle specific error cases
    if (error.response && error.response.status === 401) {
      this.errorMessage = 'Invalid credentials. Please try again.';
    } else {
      this.errorMessage = 'Server error. Please try again later.';
    }
    console.error(error.message);
  } finally {
    // Reset the loading state once the request is completed
    this.loading = false;
  }
}
,

			togglePasswordVisibility() {
				this.showPassword = !this.showPassword;
			}
		}
	};
</script>

<style scoped>
    .auth-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
		background: 
			linear-gradient(180deg, rgba(0, 8, 35, 0.64) 0%, rgba(1, 65, 34, 0.81) 100%),
			url('../assets/img/imagebackground.jpeg');
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
    }

	.logo-and-form-container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.logo-container img {
		max-width: 100px; /* Adjust based on your logo size */
		margin-bottom: 20px;
        border-radius: 50%;
	}

	@media (max-width: 600px) {
       .logo-container img {
           max-width: 80px; 
        }
    }

    @media (max-width: 400px) {
       .logo-container img {
          max-width: 60px;
        }
    }

	.alert-danger {
		border: 1px solid #FF0000;
        background-color: #FFEBEB;
		margin-bottom: 20px;
		width: 100%;
		padding: 10px 10px 10px 34px;
		border-radius: 10px;
		font-family: Poppins, sans-serif;
		text-align: center;
	}

	.form-container {
		border: 1px solid #000;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		padding: 40px;
		color: #3e4450;
		font-family: Poppins, sans-serif;
		width: 100%;
		max-width: 400px;
		border-radius: 25px;
		background: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.form-group {
		width: 100%;
		margin-bottom: 20px;
	}

	.input-icon-container {
		position: relative;
		width: 100%;
	}

	.email-icon {
		position: absolute;
		left: 10px;
		top: 50%;
		transform: translateY(-50%);
	}

	input[type='email'],
	input[type='password'] {
		width: 100%;
		padding: 10px 10px 10px 34px; /* Adjust left padding to accommodate icon */
		border-radius: 10px;
		border: 1px solid #858ea1;
		font-family: Poppins, sans-serif;
	}

	.form-group label {
		margin-bottom: 10px; /* Adds space between label and input */
		display: block; /* Ensures the label takes up the full width */
	}

	button {
		width: 100%;
		padding: 10px;
		border-radius: 10px;
		border: none;
		background-color: #14c871;
		color: white;
		cursor: pointer;
		font-family: Poppins, sans-serif;
		font-size: 16px;
	}

	.input-icon-container {
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
	}

	input[type='password'],
	input[type='text'] {
		width: 100%;
		padding: 10px;
		padding-right: 40px; /* Adjust based on the size of your SVG icon to ensure it doesn't overlap text */
		border-radius: 10px;
		border: 1px solid #858ea1;
		font-family: Poppins, sans-serif;
		/* Ensure the input field doesn't have rounded corners on the side with the icon if the icon sits flush */
	}

	.password-toggle-icon {
		position: absolute;
		cursor: pointer;
		right: 10px; /* Adjust as necessary to move closer to or further from the corner */
		top: 50%;
		transform: translateY(-50%);
		width: 14px; /* Example size, adjust based on your actual SVG size */
		height: auto;
	}
</style>
