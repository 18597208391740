<template>
  <div class="py-2 px-4">
    <div class="flex flex-row justify-content-start">
      <div class="main-theme-color text-3xl font-bold">Endpoint</div>
    </div>

    <div class="flex flex-row justify-content-between pt-2 pb-4">
      <div class="flex align-items-center justify-content-center font-bold border-round m-2">
        <BaseInput v-model="filters.value.global.value" placeholder="Search Domain/Model">
          <i class="pi pi-search"></i>
        </BaseInput>
      </div>
    </div>

    <DataTable
      v-if="filters.value && models && models.length"
      :modelValue="filters.value"
      :value="models"
      tableStyle="min-width: 50rem"
      :globalFilterFields="['model_id', 'model_name']"
      :filters="filters.value"
      :paginator="true"
      :rows="10"
      :rowsPerPageOptions="[5, 10, 15]"
      v-model:expandedRows="selectedModels"
      :metaKeySelection="metaKey"
      dataKey="model_id"
    >
      <template #empty>No models found.</template>
      <Column field="model_id" header="Fine tuned Model ID"></Column>
      <Column field="model_name" header="Model Name"></Column>
      <Column header="Signup Endpoint">
        <template #body="{ data }">
          <span>{{ getSignupEndpoint(data) }}</span>
        </template>
      </Column>
      <Column header="Login Endpoint">
        <template #body="{ data }">
          <span>{{ getLoginEndpoint(data) }}</span>
        </template>
      </Column>
      <Column header="Model URL">
        <template #body="{ data }">
          <span>{{ getModelUrl(data) }}</span>
          <button @click="openExampleRequestModal(data)">Example Request</button>
        </template>
      </Column>
    </DataTable>

    <!-- Modal -->
    <Dialog 
      header="Example Request" 
      :visible="exampleRequestModalVisible" 
      @update:visible="closeExampleRequestModal"
      :style="{ width: '80vw', maxWidth: '800px', maxHeight: '90vh', overflow: 'auto' }"
    >
      <TabView>
        <TabPanel header="CURL">
          <pre>{{ exampleRequestPayload.curl }}</pre>
        </TabPanel>
        <TabPanel header="Axios">
          <pre>{{ exampleRequestPayload.axios }}</pre>
        </TabPanel>
        <TabPanel header="Mobile">
          <pre>{{ exampleRequestPayload.mobile }}</pre>
        </TabPanel>
      </TabView>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import BaseInput from '../components/BaseInput.vue';
import Dialog from 'primevue/dialog';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { FilterMatchMode } from 'primevue/api';

const models = ref([]);
const filters = ref({
  value: {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  },
});
const selectedModels = ref([]);
const metaKey = ref(true);

const exampleRequestModalVisible = ref(false);
const exampleRequestPayload = ref({
  curl: '',
  axios: '',
  mobile: ''
});

// Fetch data and setup component
onMounted(async () => {
  try {
    const userData = JSON.parse(localStorage.getItem('userId'));
    const userId = userData.userId;

    const response = await fetch(`https://prod.islandaiforge.us/endpoints?user_id=${userId}`);
    const data = await response.json();
    console.log('data', data);

    if (data.domains && (data.open_source_models || data.fine_tuned_models)) {
      // Create a map for model_id to model_name from open_source_models and fine_tuned_models
      const modelMap = new Map();
      if (data.open_source_models) {
        data.open_source_models.forEach(model => {
          modelMap.set(model.models_id, model.model);
        });
      }
      if (data.fine_tuned_models) {
        data.fine_tuned_models.forEach(model => {
          modelMap.set(model.models_id, model.model);
        });
      }

      // Replace model_id with fine_tuned_model_id if it exists
      data.domains.forEach(domain => {
        if (modelMap.has(domain.model_id)) {
          domain.model_name = modelMap.get(domain.model_id);
        } else {
          domain.model_name = ''; // Empty string if model_name not found
        }
        
        // Optionally set model_id to fine_tuned_model_id if it exists
        if (domain.fine_tuned_model_id) {
          domain.model_id = domain.fine_tuned_model_id;
        } else {
          domain.model_id = ''; // Empty string if fine_tuned_model_id does not exist
        }
      });

      models.value = data.domains;
    } else {
      console.error('No domains or model data found in response:', data);
    }
  } catch (error) {
    console.error('Error fetching domains:', error);
  }
});

// Function to construct endpoints
const getSignupEndpoint = (data) => {
  return `https://${data.domain}/signup/`;  // Ensure https
};

const getLoginEndpoint = (data) => {
  return `https://${data.domain}/login`;  // Ensure https
};

const getModelUrl = (data) => {
  return `https://${data.domain}/generate`;  // Ensure https
};

// Function to open the example request modal
const openExampleRequestModal = (data) => {
  const basePayload = {
    method: 'POST',
    url: `https://${data.domain}/generate`,  // Use https
    headers: {
      'Content-Type': 'application/json',
    },
    fine_tuned_model_id: data.fine_tuned_model_id || null,
    body: JSON.stringify({ prompt: 'Your prompt here' }),
  };

  exampleRequestPayload.value = {
    curl: `curl -X ${basePayload.method} ${basePayload.url} -H "Content-Type: application/json" -d '{ "fine_tuned_model_id": "${data.fine_tuned_model_id}", "prompt": "Your prompt here" }'`,
    axios: `axios({
      method: '${basePayload.method}',
      url: '${basePayload.url}',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        fine_tuned_model_id: '${data.fine_tuned_model_id}',  // Optional parameter
        prompt: 'Your prompt here'
      }
    })`,
    mobile: `{
      method: '${basePayload.method}',
      url: '${basePayload.url}',
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        fine_tuned_model_id: '${data.fine_tuned_model_id}',  // Optional parameter
        prompt: 'Your prompt here'
      }
    }`
  };

  exampleRequestModalVisible.value = true;
};

// Function to close the example request modal
const closeExampleRequestModal = () => {
  console.log('Closing modal');
  exampleRequestModalVisible.value = false;
};
</script>

<style scoped>
.text-brand-color {
  color: green;
}

.btn-request-install {
  background-color: green;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}

.btn-request-click {
  background-color: rgb(52, 179, 52);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  animation: pulsate 1s infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(0, 140, 186, 0.7);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 20px rgba(0, 140, 186, 0.7);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(1, 95, 43, 0.7);
  }
}

.ui-datatable .ui-state-highlight {
  background-color: green;
}
</style>
