<template>
	<div class="main-content-container">
		<div class="main-left-column">
			<!-- Embed YouTube video -->
			<!-- Example placeholder: Replace "VIDEO_ID" with your actual video ID -->
			<iframe
				width="500"
				height="315"
				src="https://www.youtube.com/embed/anmuklFtu8U"
				title="YouTube video player"
				frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowfullscreen
			></iframe>
		</div>
		<div class="main-right-column">
			<div class="use-case-header">Use case examples</div>

			<div class="use-case-row">
				<div class="use-case-column">
					<div class="use-case-icon">
						<i class="fas fa-robot icon-color"></i>
					</div>
					<div class="use-case-content">
						<div class="use-case-title">Custom Chatbot</div>
						<div class="use-case-text">A custom chatbot designed to assist customers with inquiries and provide personalized recommendations.</div>
					</div>
				</div>
				<div class="use-case-column">
					<div class="use-case-icon"><i class="fas fa-phone-alt"></i></div>
					<div class="use-case-content">
						<div class="use-case-title">Call center quality assurance</div>
						<div class="use-case-text">An AI-powered system that analyzes call center interactions to ensure quality service and compliance.</div>
					</div>
				</div>
			</div>
			<div class="use-case-row">
				<div class="use-case-column">
					<div class="use-case-icon"><i class="fas fa-user-md"></i></div>
					<div class="use-case-content">
						<div class="use-case-title">Medical health diagnosis</div>
						<div class="use-case-text">AI algorithms used to assist medical professionals in diagnosing illnesses and recommending treatments.</div>
					</div>
				</div>
				<div class="use-case-column">
					<div class="use-case-icon"><i class="fas fa-file-video"></i></div>
					<div class="use-case-content">
						<div class="use-case-title">Text to video Generation</div>
						<div class="use-case-text">Automated system that converts text-based content into engaging video presentations.</div>
					</div>
				</div>
			</div>
			<!-- Additional use cases if needed -->
		</div>
	</div>
</template>

<script>
	import '@fortawesome/fontawesome-free/css/all.css';

	export default {
		name: 'BaseContent'
	};
</script>

<style scoped>
	main-content-container {
		display: flex;
		gap: 20px; /* Adjust the space between the columns as necessary */
		margin-top: 20px; /* Adds some space above the container */
		flex-direction: column;
	}

	.main-left-column,
	.main-right-column {
		flex: 1; /* This makes each column take up equal space */
	}

	@media (max-width: 768px) {
		.main-content-container {
			flex-direction: column; /* Stacks the columns vertically on smaller screens */
		}
	}
	.main-left-column {
		border-radius: 10px; /* Adjust the value as needed */
		overflow: hidden; /* Ensures the iframe content stays within the rounded border */
		margin-right: 40px;
	}

	.video-iframe {
		width: 100%; /* Ensures the iframe fills its container */
		height: 100%; /* Ensures the iframe fills its container */
	}
	.main-right-column {
		display: flex;
		flex-direction: column;
	}

	.use-case-row {
		display: flex;
		gap: 30px; /* Adjust the spacing between columns */
	}

	.use-case-column {
		flex: 1;
		display: flex;
		flex-direction: row; /* Ensure columns stack vertically */
		margin-bottom: 24px;
	}

	.use-case-icon {
		margin-right: 5px; /* Add some space between the icon and content */
	}

	.icon-color {
		color: #746;
	}
	.use-case-icon {
		color: #746;
	}

	.use-case-title {
		color: #3e4450;

		font-family: Poppins;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
	.use-case-header {
		margin-bottom: 40px;
		color: #14c871;

		font-family: Poppins;
		font-size: 22px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.use-case-text {
		font-size: 14px;
	}
</style>
