<template>
  <div class="billing-container">
    <h2 class="billing-title">Usage & Billing</h2>

    <!-- Show Loading Message Until Data is Loaded -->
    <div v-if="!isDataLoaded">
      <p>Loading billing data...</p>
    </div>

    <!-- Billing and Free Trial Information Side-by-Side -->
    <div class="billing-info-container" v-if="isDataLoaded">
      <!-- Trial Information -->
      <div class="trial-info">
        <h3>Free Trial</h3>
        <p v-if="isOnFreeTrial"><strong>You're currently on a free trial!</strong></p>
        <p v-if="isOnFreeTrial"><strong>Remaining Trial Time:</strong> {{ remainingTrialTime }} minutes</p>
        <p v-else><strong>You are not on a free trial.</strong></p>
      </div>

      <!-- Current Bill Information -->
      <div class="bill-info">
        <h3>Billing Details</h3>
        <p><strong>Total minutes:</strong> {{ currentBill.toFixed(2) }} minutes</p>
        <p><strong>Minutes Used:</strong> {{ minutesUsed }} minutes</p>
      </div>
    </div>

    <!-- Buy Hours -->
    <div class="buy-hours" v-if="isDataLoaded">
      <p><strong>Buy more minutes:</strong></p>
      <div class="hour-options">
        <button
          v-for="hour in availableHours"
          :key="hour"
          class="buy-button"
          @click="selectMinutesToBuy(hour)"
        >
          {{ hour }} hours (${{ (hour * 60 * costPerMinute).toFixed(2) }})
        </button>
      </div>
    </div>

    <!-- Payment Modal -->
    <div class="payment-modal" v-if="showPaymentModal">
      <div class="modal-content">
        <h3>Complete Payment</h3>
        <p>You are about to pay ${{ totalExtraCost.toFixed(2) }} for {{ selectedMinutesToBuy }} minutes.</p>
        
        <!-- Stripe Elements -->
        <div id="payment-form">
          <div id="card-element"></div>
        </div>
        
        <button @click="processPayment" class="pay-button">Pay</button>
        <button @click="closeModal" class="cancel-button">Cancel</button>
      </div>
    </div>
  </div>
</template>


<script>
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

export default {
  name: "BillingPage",
  data() {
    return {
      costPerMinute: 0.24,
      minutesUsed: 0,
      currentBill: 0,
      paymentThreshold: 0,
      isDataLoaded: false,
      availableHours: [1,3, 10, 20, 100, 200, 500],
      totalExtraCost: 0,
      selectedMinutesToBuy: 0,
      showPaymentModal: false,
      stripe: null,
      cardElement: null,
      clientSecret: null,
      isOnFreeTrial: false,  // Variable to track if the user is on a free trial
      remainingTrialTime: 0,  // Remaining minutes for the free trial
    };
  },
  methods: {
    async fetchBillingData() {
      try {
        const userId = localStorage.getItem("userId");
        if (!userId) throw new Error("User ID not found in localStorage");

        const baseUrl = process.env.VUE_APP_BASE_URL;
        const response = await axios.get(`${baseUrl}/userinfo/${userId}`);
        const { minutes_used, total_package_minutes, payment_threshold, freetrial, remaining_free_minutes } = response.data;
       // console.log('is on trial',response.data)

        this.minutesUsed = minutes_used;
        this.currentBill = total_package_minutes;
        this.paymentThreshold = payment_threshold;
        this.isOnFreeTrial = freetrial;  // Set trial status
        this.remainingTrialTime = remaining_free_minutes;  // Set remaining trial time
        this.isDataLoaded = true;

        // Load Stripe
        this.stripe = await loadStripe("pk_live_51MnMGrE1uOh1UBiwgHnSC8b8OKZhFEuSGIHjTa88gUmJ82JADCkaadlPd0tOrjc6JfrLkGSOkV714SgnV8QkvlKF00PrqzpBgj");
      } catch (error) {
        //console.error("Error fetching billing data:", error);
        alert("Failed to load billing data. Please try again later.");
      }
    },
    selectMinutesToBuy(hours) {
      this.selectedMinutesToBuy = hours * 60;
      this.totalExtraCost = Math.round(this.selectedMinutesToBuy * this.costPerMinute * 100) / 100;
      this.showPaymentModal = true;

      // Ensure clientSecret and cardElement are reset before reinitialization
      this.clientSecret = null;
      this.cardElement = null;

      // Initialize Stripe Elements when the modal opens
      this.initializeStripeElements();
    },
    closeModal() {
      this.showPaymentModal = false;
    },
    async initializeStripeElements() {
      try {
        const baseUrl = 'https://prod.islandaiforge.us';
        const userId = localStorage.getItem("userId");

        // Create a payment intent on the backend
        const { data } = await axios.post(`${baseUrl}/create-payment-intent`, {
          amount: this.totalExtraCost * 100, // The amount to charge in cents
          metadata: {
            package_minutes: this.selectedMinutesToBuy.toString(), // Attach the selected minutes
            user_id: userId.toString(), // Attach the user ID
          },
        });
        this.clientSecret = data.clientSecret;

        // Mount Stripe Elements
        const elements = this.stripe.elements();
        this.cardElement = elements.create("card");
        this.cardElement.mount("#card-element");
      } catch (error) {
       // console.error("Error initializing payment:", error);
        alert("Unable to initialize payment. Please try again.");
      }
    },
    async processPayment() {
      try {
        const { error, paymentIntent } = await this.stripe.confirmCardPayment(this.clientSecret, {
          payment_method: {
            card: this.cardElement,
          },
        });

        if (error) {
          //console.error("Payment error:", error);
          alert("Payment failed. Please try again.");
        } else if (paymentIntent.status === "succeeded") {
          alert("Payment successful!");
          this.showPaymentModal = false;
          await axios.post('https://prod.islandaiforge.us/confirm-payment/', { paymentIntentId: paymentIntent.id });
          await this.fetchBillingData();
        } else {
          alert("Payment processing. Please wait.");
        }
      } catch (error) {
        //console.error("Error processing payment:", error);
        alert("An error occurred. Please try again later.");
      }
    },
  },
  mounted() {
    this.fetchBillingData();
  },
};
</script>

<style scoped>
.billing-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.billing-title {
  text-align: center;
  margin-bottom: 20px;
}

/* Flex container for side-by-side display */
.billing-info-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.trial-info, .bill-info {
  flex: 1;
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.trial-info h3, .bill-info h3 {
  margin-bottom: 10px;
}

.buy-hours {
  margin-top: 20px;
}

.buy-button {
  background-color: #14c871;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 4px;
  cursor: pointer;
}

.buy-button:hover {
  background-color: #12a163;
}

.payment-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pay-button {
  background-color: #14c871;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button {
  background-color: #ff6f61;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}
</style>
